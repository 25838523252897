import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class InstructorService {
  async getInstructor() {
    return await fetchWrapper.get(`${ruta}/instructores`);
  }
  async saveInstructor(datos) {
    return await fetchWrapper.post(`${ruta}/instructores`, datos);
  }
  async updateInstructor(datos) {
    return await fetchWrapper.put(`${ruta}/instructores/` + datos.id, datos);
  }
  async updateContraseña(datos) {
    return await fetchWrapper.post(`${ruta}/updateContrasena`, datos);
  }
  async buscarPais(pais) {
    return await fetchWrapper.post(`${ruta}/buscarPais`, pais);
  }
  async uploadImage(img) {
    return await fetchWrapper.post(`${ruta}/uploadImage/instructor`, img);
  }
  async instructordataModificar(id) {
    return await fetchWrapper.get(`${ruta}/instructor/` + id);
  }
  async activarDesactivar(id) {
    return await fetchWrapper.put(`${ruta}/instructores/activar/desactivar/${id}`);
  }
}
