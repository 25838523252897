<template>
  <!-- Login Banner -->
  <div class="col-md-6 login-bg">
    <div class="owl-carousel login-slide owl-theme">
      <div class="welcome-login">
        <div class="login-banner">
          <img
            src="@/assets/img/Recurso 651.png"
            class="img-fluid"
            alt="Logo"
          />
        </div>
        <Carousel
          :items-to-show="1"
          :autoplay="2000"
          :wrap-around="true"
          :pauseAutoplayOnHover="true"
        >
          <Slide key="0">
            <div class="mentor-course text-center">
              <h6>
                Mejora tu carrera en T.I. con la experiencia de Papo y los demás
                instructores, además de un contenido único y accesible. <br />
                Empieza ya!!
              </h6>
            </div>
          </Slide>
          <Slide key="1">
            <div class="mentor-course text-center">
              <img src="@/assets/img/redess.png" class="img-fluid" alt="Logo" />
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
  <!-- /Login Banner -->
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Autoplay",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
});
</script>
<style scoped>
/* Fondo de login */
.login-bg {
  background: #14123b; /* Color de fondo (antes era un gradiente) */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

/* Estilo del banner */
.welcome-login {
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* Fondo translúcido */
  border-radius: 20px;
  background-color: #73cbd6;
  margin-bottom: 30px;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(115, 203, 214, 0.7);
}

.login-banner img {
  width: 400px;
  margin-bottom: 20px;
  border-color: #73cbd6;
}

/* Estilo de la parte del texto en el carousel */
.mentor-course {
  background: rgba(255, 255, 255, 0.7); /* Fondo translúcido para el texto */
  padding: 10px;
  border-radius: 20px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

h6 {
  font-size: 22px;
  color: #14123b;
  font-weight: 400;
}

/* Estilos del carousel y paginación */
.owl-carousel {
  width: 100%;
}

.owl-carousel .owl-nav button {
  background-color: #685f89; /* Color de los botones de navegación */
  border-radius: 50%;
}

.pagination {
  color: #685f89;
  text-align: center;
}

/* Transición y sombras */
.welcome-login,
.mentor-course {
  transition: transform 0.3s ease-in-out;
}

.welcome-login:hover,
.mentor-course:hover {
  transform: translateY(-5px); /* Efecto hover: levanta los elementos */
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .mentor-course h6 {
    font-size: 14px; /* Ajusta el tamaño de la fuente en pantallas pequeñas */
  }
}
</style>

