<template>
  <page-header></page-header>
  <coursedetails></coursedetails>
  <!-- Course Content -->
  <div class="inner-banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="instructor-wrap border-bottom-0 m-0">
            <div class="about-instructor align-items-center">
              <div class="abt-instructor-img">
                <img :src="curso.foto_instructor" alt="img" class="img-fluid" />
              </div>
              <div class="instructor-detail me-3">
                <h5>
                  {{ curso.instructor ?? "" }}
                </h5>
                <p>{{ curso.profesion ?? "" }}</p>
              </div>
              <div class="rating mb-0" v-if="false">
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <span class="d-inline-block Average-rating"
                  ><span>4.5</span> (15)</span
                >
              </div>
            </div>
            <span class="web-badge mb-3">{{ curso.categoria ?? "" }}</span>
          </div>
          <h2>{{ curso.nombre ?? "" }}</h2>
          <p>
            {{ curso.descripcion_corta ?? "" }}
          </p>
          <div
            class="course-info d-flex align-items-center border-bottom-0 m-0 p-0"
          >
            <div class="cou-info">
              <img src="@/assets/img/icon/icon-01.svg" alt="" />
              <p>{{ curso.temas }}+ Temas</p>
            </div>
            <div class="cou-info">
              <img src="@/assets/img/icon/timer-icon.svg" alt="" />
              <p>{{ curso.duracion }}</p>
            </div>
            <div class="cou-info" v-if="false">
              <img src="@/assets/img/icon/people.svg" alt="" />
              <p>32 Estudiantes matriculados</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="page-content curso-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Overview -->
          <div class="card overview-sec">
            <div class="card-body">
              <h5 class="subs-title">Descripción general</h5>
              <p v-html="curso.descripcion ?? ''"></p>
              <!-- <h6>Lo que aprenderás</h6>
              <div class="row">
                <div class="col-md-6">
                  <ul>
                    <li>Conviértete en diseñador de UX.</li>
                    <li>Podrás agregar diseñador de UX a tu CV.</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>
                    <li>
                      Aprende a diseñar sitios web y aplicaciones para teléfonos
                      móviles.
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- <h6>Requisitos</h6>
              <ul class="mb-0">
                <li>
                  Necesitará una copia de Adobe XD 2023 o posterior. Puede
                  descargar una versión de prueba gratuita desde Adobe.
                </li>
                <li>No se necesita experiencia previa en diseño.</li>
                <li class="mb-0">
                  No se necesitan conocimientos previos de Adobe XD.
                </li>
              </ul> -->
            </div>
          </div>
          <!-- /Overview -->

          <!-- Course Content -->
          <div class="card content-sec">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="subs-title">Contenido del curso</h5>
                </div>
                <div class="col-sm-6 text-sm-end">
                  <h6>
                    {{ curso.temas ?? "-" }} Temas {{ curso.duracion ?? "-" }}
                  </h6>
                </div>
              </div>
              <div>
                <div
                  v-for="(modulo, index) in modulos"
                  :key="modulo.id"
                  class="curso-card"
                >
                  <h6 class="cou-title">
                    <a
                      class="collapsed"
                      :data-bs-toggle="'collapse'"
                      :href="`#collapse-${modulo.id}`"
                      :aria-expanded="index === 0"
                    >
                      {{ modulo.nombre }}
                    </a>
                  </h6>
                  <div
                    :id="`collapse-${modulo.id}`"
                    :class="['card-collapse', { collapse: index !== 0 }]"
                  >
                    <ul>
                      <li
                        v-for="tema in modulo.temas"
                        :key="tema.id"
                        class="tema-item"
                      >
                        <div class="tema-linea">
                          <!-- Imagen -->
                          <img
                            src="@/assets/img/icon/play.svg"
                            alt="Icono tema"
                            class="tema-icono"
                          />
                          <!-- Nombre del tema -->
                          <span class="tema-nombre">{{ tema.nombre }}</span>
                          <span
                            v-if="tema.recursos && tema.recursos.length"
                            class="tema-recursos"
                          >
                            <!-- Recursos -->
                            <span
                              v-for="recurso in tema.recursos"
                              :key="recurso.id"
                              class="recurso-item"
                            >
                              <a
                                :href="recurso.url"
                                target="_blank"
                                class="recurso-link"
                                >Visualizar</a
                              >
                              <span class="recurso-duracion">{{
                                recurso.duracion
                              }}</span>
                            </span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contenido del curso -->

          <!-- Instructor -->
          <div class="card instructor-sec">
            <div class="card-body">
              <h5 class="subs-title">Acerca del instructor</h5>
              <div class="instructor-wrap">
                <div class="about-instructor">
                  <div class="abt-instructor-img">
                    <img
                      :src="curso.foto_instructor"
                      alt="img"
                      class="img-fluid"
                    />
                  </div>
                  <div class="instructor-detail">
                    <h5>
                      {{ curso.instructor ?? "" }}
                    </h5>
                    <p>{{ curso.profesion ?? "" }}</p>
                  </div>
                </div>
                <div class="rating">
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <span class="d-inline-block Average-rating"
                    >4.5 Calificación del instructor</span
                  >
                </div>
              </div>
              <div class="curso-info d-flex align-items-center">
                <div class="cou-info">
                  <img src="@/assets/img/icon/play.svg" alt="" />
                  <p>{{ curso.cursos_instructor ?? "" }} cursos</p>
                </div>
                <div class="cou-info">
                  <img src="@/assets/img/icon/icon-01.svg" alt="" />
                  <p>M&aacute;s de {{ curso.temas_instructor ?? "" }} temas</p>
                </div>
                <div class="cou-info" v-if="false">
                  <img src="@/assets/img/icon/icon-02.svg" alt="" />
                  <p>9 h 30 min</p>
                </div>
                <div class="cou-info" v-if="false">
                  <img src="@/assets/img/icon/people.svg" alt="" />
                  <p>270 866 estudiantes matriculados</p>
                </div>
              </div>
              <p v-html="curso.biografia_instructor ?? ''"></p>
            </div>
          </div>
          <!-- /Instructor -->

          <!-- Reviews -->
          <div class="card review-sec" v-if="false">
            <div class="card-body">
              <h5 class="subs-title">Reseñas</h5>
              <div class="instructor-wrap">
                <div class="about-instructor">
                  <div class="abt-instructor-img">
                    <router-link to="/instructor/instructor-profile"
                      ><img
                        src="@/assets/img/user/user1.jpg"
                        alt="img"
                        class="img-fluid"
                    /></router-link>
                  </div>
                  <div class="instructor-detail">
                    <h5>
                      <router-link to="/instructor/instructor-profile"
                        >Nicole Brown</router-link
                      >
                    </h5>
                    <p>Diseñador UX/UI</p>
                  </div>
                </div>
                <div class="rating">
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star me-1"></i>
                  <span class="d-inline-block Average-rating"
                    >4.5 Calificación del instructor</span
                  >
                </div>
              </div>
              <p class="rev-info">
                “ Este es el segundo curso de Photoshop que he completado con
                Cristian. Vale cada centavo y lo recomiendo ampliamente. Para
                aprovechar al máximo este curso, es mejor tomar primero el curso
                de Principiante a Avanzado. La calidad del sonido y el video es
                de un buen nivel. Gracias, Cristian. “
              </p>
              <a href="javascript:;" class="btn btn-reply"
                ><i class="feather-corner-up-left"></i> Responder</a
              >
            </div>
          </div>
          <!-- /Reseñas -->

          <!-- Comentario -->
          <div class="card comment-sec" v-if="false">
            <div class="card-body">
              <h5 class="subs-title">Publicar un comentario</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nombre completo"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Correo electrónico"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Asunto"
                  />
                </div>
                <div class="form-group mb-3">
                  <textarea
                    rows="4"
                    class="form-control"
                    placeholder="Sus comentarios"
                  ></textarea>
                </div>
                <div class="submit-section">
                  <button class="btn submission-btn" type="submit">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- /Comentario -->
        </div>

        <div class="col-lg-4">
          <div class="sidebar-sec">
            <!-- Vídeo -->
            <div class="video-sec vid-bg">
              <div class="card">
                <div class="card-body">
                  <div class="col-md-5 d-flex align-items-center">
                    <video
                      id="video-player"
                      class="video-js vjs-default-skin"
                      controls
                      preload="auto"
                    ></video>
                  </div>
                  <div class="video-details">
                    <div class="curso-fee">
                      <h3>Bs. {{ curso.precio ?? "" }}</h3>
                      <p>
                        <span
                          >Antes Bs. {{ parseFloat(curso.precio) + 50 }}</span
                        >
                      </p>
                    </div>
                    <div class="row gx-2" v-if="false">
                      <div class="col-md-6">
                        <router-link
                          to="curso-wishlist"
                          class="btn btn-wish w-100"
                          ><i class="feather-heart"></i> Agregar</router-link
                        >
                      </div>
                      <div class="col-md-6">
                        <a href="javascript:;" class="btn btn-wish w-100"
                          ><i class="feather-share-2"></i> Compartir</a
                        >
                      </div>
                    </div>
                    <!-- <router-link
                      to="/pages/checkout"
                      class="btn btn-cuadrado w-100"
                      >Inscribirse ahora</router-link
                    > -->
                    <router-link
                      :to="`/pages/pago/${curso.id}`"
                      class="button btn-cuadrado w-100"
                    >
                      <span
                        ><i class="fas fa-shopping-cart"></i> COMPRAR
                        AHORA</span
                      >
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Video -->

            <!-- Include -->
            <div class="card include-sec">
              <div class="card-body">
                <div class="cat-title">
                  <h4>Incluye</h4>
                </div>
                <ul>
                  <li>
                    <img
                      src="@/assets/img/icon/timer.svg"
                      class="me-2"
                      alt=""
                    />
                    {{ curso.duracion ?? "" }} horas de video a pedido
                  </li>
                  <li>
                    <img
                      src="@/assets/img/icon/chapter.svg"
                      class="me-2"
                      alt=""
                    />
                    Temas: <span>{{ curso.temas ?? "" }}</span>
                  </li>
                  <li v-if="false">
                    <img src="@/assets/img/icon/play.svg" class="me-2" alt="" />
                    69 recursos descargables
                  </li>
                  <li>
                    <img src="@/assets/img/icon/key.svg" class="me-2" alt="" />
                    Acceso completo de por vida
                  </li>
                  <li v-if="false">
                    <img
                      src="@/assets/img/icon/mobile.svg"
                      class="me-2"
                      alt=""
                    />
                    Acceso en dispositivos móviles y PC
                  </li>
                  <li v-if="false">
                    <img
                      src="@/assets/img/icon/cloud.svg"
                      class="me-2"
                      alt=""
                    />
                    Tareas
                  </li>
                  <li>
                    <img
                      src="@/assets/img/icon/teacher.svg"
                      class="me-2"
                      alt=""
                    />
                    Certificado de finalización
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Include -->

            <!-- Features -->
            <div class="card feature-sec" v-if="false">
              <div class="card-body">
                <div class="cat-title">
                  <h4>Incluye</h4>
                </div>
                <ul>
                  <li v-if="false">
                    <img
                      src="@/assets/img/icon/users.svg"
                      class="me-2"
                      alt=""
                    />
                    Matriculados: <span>32 alumnos</span>
                  </li>
                  <li>
                    <img
                      src="@/assets/img/icon/timer.svg"
                      class="me-2"
                      alt=""
                    />
                    Duración: <span>{{ curso.duracion ?? "" }}</span>
                  </li>
                  <li>
                    <img
                      src="@/assets/img/icon/chapter.svg"
                      class="me-2"
                      alt=""
                    />
                    Temas: <span>{{ curso.temas ?? "" }}</span>
                  </li>
                  <li v-if="false">
                    s
                    <img
                      src="@/assets/img/icon/video.svg"
                      class="me-2"
                      alt=""
                    />
                    Vídeo:<span> {{ curso.duracion ?? "" }} horas</span>
                  </li>
                  <li v-if="false">
                    <img
                      src="@/assets/img/icon/chart.svg"
                      class="me-2"
                      alt=""
                    />
                    Nivel: <span>Principiante</span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Features -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Pricing Plan -->
  <layouts1></layouts1>
</template>
<script>
import CursoService from "@/service/CursoService";
import videojs from "video.js"; // Importa la librería de Video.js
import "videojs-youtube";

export default {
  data() {
    return {
      curso: {},
      modulos: [],
      reproduciendo: false,
      player: null, // Referencia al reproductor de video
    };
  },
  cursoService: null,
  created() {
    this.cursoService = new CursoService();
  },

  mounted() {
    this.$nextTick(() => {
      this.obtenerCurso();
      this.cargarVideo(); // Llama a la función para cargar el video
    });
  },
  methods: {
    cargarVideo() {
      // Inicializa el reproductor de Video.js
      this.player = videojs("video-player", {
        techOrder: ["youtube"], // Especifica que se usará YouTube
        sources: [
          {
            type: "video/youtube",
            src: "https://www.youtube.com/watch?v=eqnmnwen7iM", // URL del video
          },
        ],
        controls: true,
        preload: "auto",
        //hago que se reproduzca automáticamente
        autoplay: true,
        //que sea mute
        muted: true,

        // Opciones de apariencia
        controlBar: {
          volumePanel: {
            inline: false,
          },
        },
      });
    },
    obtenerCurso() {
      this.cursoService
        .detalleCurso(this.$route.params.id)
        .then((response) => {
          this.curso = response.curso;
          this.modulos = response.modulos;
          console.log(this.modulos);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style>
@import "https://vjs.zencdn.net/8.0.4/video-js.css";

.tema-linea {
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: space-between; /* Deja espacio entre el contenido y los enlaces */
  padding: 5px 0; /* Espaciado interno */
  border-bottom: 1px solid #ddd; /* Línea divisoria opcional */
}

.tema-icono {
  width: 20px; /* Tamaño de la imagen */
  height: 20px;
  margin-right: 10px;
}

.tema-nombre {
  flex: 1; /* El nombre del tema ocupa el espacio disponible */
  font-weight: 500;
}

.tema-recursos {
  display: flex;
  align-items: center; /* Alinea los elementos horizontalmente */
  gap: 10px; /* Espaciado entre recursos */
}

.recurso-link {
  color: #007bff; /* Azul por defecto */
  text-decoration: none;
  font-weight: bold;
  margin-right: 5px;
}

.recurso-duracion {
  color: #6c757d; /* Color gris para duración */
  font-size: 0.9em; /* Tamaño de texto reducido */
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
