<template>
  <page-header></page-header>
  <!-- <checkoutbreadcrumb></checkoutbreadcrumb> -->
  <!-- Carrito -->
  <section class="course-content checkout-widget">
    <div class="container">
      <div class="row">
        <!-- Información de Pago -->
        <div class="col-lg-8">
          <!-- Dirección de Facturación -->
          <div class="student-widget">
            <div class="student-widget-group add-course-info">
              <div class="cart-head">
                <h4><strong>Realizar Pago</strong> : {{ curso.nombre }}</h4>
              </div>
              <div class="checkout-form">
                <div class="row">
                  <div class="col-lg-6">
                    <strong>Nombres:</strong>
                    <input
                      v-model="nombre_pago"
                      type="text"
                      class="form-control form-input"
                      placeholder="Ingresa tu nombre"
                    />
                  </div>
                  <div class="col-lg-6">
                    <strong>Apellidos: </strong>
                    <input
                      v-model="apellido_pago"
                      type="text"
                      class="form-control form-input"
                      placeholder="Ingresa tu apellido"
                    />
                  </div>
                  <div class="col-lg-4 mt-3">
                    <strong>N&uacute;mero de T&eacute;lefono:</strong>
                    <input
                      v-model="telefono_pago"
                      type="text"
                      class="form-control form-input"
                      placeholder="Número de Teléfono"
                    />
                  </div>
                  <div class="col-lg-8 mt-3">
                    <strong>Direcci&oacute;n/Ubicaci&oacute;n:</strong>
                    <input
                      v-model="direccion_pago"
                      type="text"
                      class="form-control form-input"
                      placeholder="Dirección"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Método de Pago con pestañas -->
          <div class="student-widget pay-method mt-4">
            <div class="student-widget-group add-course-info">
              <div class="cart-head">
                <h4><strong>Método de Pago</strong></h4>
              </div>
              <div class="checkout-form">
                <div class="tabs">
                  <!-- Pestañas -->
                  <ul class="nav nav-tabs" id="paymentTabs" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        :class="{ active: metodoPago === 'qr' }"
                        id="qr-tab"
                        data-bs-toggle="tab"
                        href="#qr-payment"
                        role="tab"
                        aria-controls="qr-payment"
                        aria-selected="true"
                        @click="metodoPago = 'qr'"
                      >
                        Pago por QR
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        :class="{ active: metodoPago === 'paypal' }"
                        id="paypal-tab"
                        data-bs-toggle="tab"
                        href="#paypal-payment"
                        role="tab"
                        aria-controls="paypal-payment"
                        aria-selected="false"
                        @click="metodoPago = 'paypal'"
                      >
                        PayPal
                      </a>
                    </li>
                  </ul>

                  <!-- Contenido de las pestañas -->
                  <div class="tab-content" id="paymentTabsContent">
                    <!-- Pago por QR -->
                    <div
                      class="tab-pane fade"
                      :class="{ 'show active': metodoPago === 'qr' }"
                      id="qr-payment"
                      role="tabpanel"
                      aria-labelledby="qr-tab"
                    >
                      <div class="qr-code">
                        <img
                          src="@/assets/img/qr.png"
                          alt="Código QR"
                          class="qr-image"
                        />
                      </div>
                    </div>

                    <!-- Pago por PayPal -->
                    <div
                      class="tab-pane fade"
                      :class="{ 'show active': metodoPago === 'paypal' }"
                      id="paypal-payment"
                      role="tabpanel"
                      aria-labelledby="paypal-tab"
                    >
                      <div class="paypal-method">
                        <p>
                          Inicia sesión en tu cuenta PayPal para realizar el
                          pago.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <button
                    class="btn btn-cuadrado w-100"
                    type="submit"
                    @click="realizarPago"
                    :loading="validandoPago"
                  >
                    Realizar el Pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Resumen del Plan -->
        <div class="col-lg-4 theiaStickySidebar">
          <div class="stickysidebar">
            <div class="student-widget select-plan-group">
              <div class="basic-plan">
                <img
                  class="img-fluid"
                  :alt="curso.nombre"
                  :src="curso.imagen"
                />
                <p>
                  {{ curso.descripcion_corta }}
                </p>
                <h2><span>Bs.</span>{{ curso.precio }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <whatsapp />
</template>

<script>
import CursoService from "@/service/CursoService";
import Message from "primevue/message";
import { useAuth } from "@/stores"; // Importar el store de autenticación

export default {
  components: {
    Message,
  },
  data() {
    return {
      curso: {},
      validandoPago: false,
      metodoPago: "qr",
      nombre_pago: "",
      apellido_pago: "",
      telefono_pago: "",
      direccion_pago: "",
    };
  },
  cursoService: null,
  auth: null,
  created() {
    this.cursoService = new CursoService();
    this.auth = useAuth(); // Inicializar el store de autenticación
  },

  mounted() {
    this.obtenerCurso();
  },
  methods: {
    obtenerCurso() {
      this.cursoService
        .detalleCurso(this.$route.params.id)
        .then((response) => {
          this.curso = response.curso;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    realizarPago() {
      this.validandoPago = true;
      let data = {
        curso_id: this.curso.id,
        estudiante_id: this.auth.user
          ? this.auth.user.instructor_estudiante_id
          : null,
        instructor_id: this.curso.instructor_id,
        metodo_pago: this.metodoPago,
        precio: this.curso.precio,
        nombre_apellido_pago: this.nombre_pago
          ? `${this.nombre_pago} ${this.apellido_pago}`
          : "",
        telefono_pago: this.telefono_pago ? this.telefono_pago : "",
        direccion_pago: this.direccion_pago ? this.direccion_pago : "",
      };

      try {
        this.cursoService
          .realizarPago(data)
          .then((response) => {
            // Verificar si la inscripción fue exitosa
            if (response.success) {
              this.$message.success(response.message); // Mensaje de éxito
              this.$router.push("/student/student-courses"); // Redirigir al listado de cursos del estudiante
            } else {
              // Mensaje de error si la respuesta del backend no es exitosa
              this.$message.error(response.message || "Error desconocido");
            }
          })
          .catch((error) => {
            // Aquí manejamos el error de manera más detallada
            console.error(error); // Asegúrate de que el error esté siendo capturado correctamente
            if (error.response && error.response.data) {
              const errorMessage =
                error.response.data.message || "Error desconocido";
              this.$message.error(errorMessage); // Mostrar el error al usuario
            } else {
              this.$message.error("Ocurrió un error al realizar el pago");
            }
          });
      } catch (error) {
        // Manejo de errores inesperados
        console.log(error);
        this.$message.error("Error inesperado");
      } finally {
        // Restablecer el estado de la validación
        this.validandoPago = false;
      }
    },
  },
};
</script>

<style scoped>
/* Fondo con animación */
.course-content {
  position: relative;
  background: #171723; /* Fondo oscuro */
  padding: 80px 0px;
  overflow: hidden;
}

.course-content::before,
.course-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(115, 203, 214, 0.1),
    rgba(132, 117, 182, 0.1)
  );
  z-index: 1;
  transform: rotate(25deg);
  animation: moveLines 6s linear infinite;
}

.course-content::after {
  animation-delay: 3s;
}

@keyframes moveLines {
  0% {
    transform: translateX(-50%) rotate(25deg);
  }
  100% {
    transform: translateX(50%) rotate(25deg);
  }
}

/* Asegurar que el contenido esté encima */
.container {
  position: relative;
  z-index: 2;
}

/* Diseño de los formularios y botones */
.student-widget {
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 10px;
}

.form-input {
  border: 1px solid #233269;
  border-radius: 5px;
}

.qr-code {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  animation: fadeIn 0.5s ease-in-out;
}

.qr-image {
  width: 200px;
  height: 200px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* Estilos para las pestañas de método de pago */
.nav-tabs {
  border-bottom: 2px solid #ddd;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease;
}

.nav-link.active {
  background-color: #73cbd6;
  color: white;
  border-color: #73cbd6;
}

.nav-link:hover {
  background-color: #e6f7f8;
}

.tab-content {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.qr-code {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.paypal-method {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.paypal-method p {
  font-size: 16px;
  color: #333;
}
</style>
