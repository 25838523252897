<template>
  <!-- Header -->
  <header class="header header-page">
    <div class="header-fixed">
      <nav
        class="navbar navbar-expand-lg header-nav scroll-sticky"
        :class="{ 'add-header-bg': isSticky }"
      >
        <div class="container">
          <div class="navbar-header">
            <a
               id="mobile_btn"
              href="javascript:void(0);"
              @click="toggleSidebar"
            >
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <router-link to="/" class="navbar-brand logo">
              <img
                src="@/assets/img/logo/logo_cabecera2.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link>
          </div>
          <ul class="nav header-navbar-rht">
            <div class="main-nav mx-3">
              <main-nav></main-nav>
            </div>
            <li class="nav-item user-nav" v-if="false">
              <div>
                <a
                  href="#"
                  id="dark-mode-toggle"
                  ref="darkModeToggle"
                  class="dark-mode-toggle"
                  @click="enableDarkMode"
                >
                  <i class="fa-solid fa-moon"></i>
                </a>
                <a
                  href="#"
                  id="light-mode-toggle"
                  class="dark-mode-toggle"
                  ref="lightModeToggle"
                  @click="disableDarkMode"
                >
                  <i class="fa-solid fa-sun"></i>
                </a>
              </div>
            </li>
            <li class="nav-item user-nav">
              <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
                <span class="user-img">
                  <img :src="src" alt="Img" />
                  <span class="status online"></span>
                </span>
              </a>
              <div
                class="users dropdown-menu dropdown-menu-right"
                data-popper-placement="bottom-end"
              >
                <div class="user-header">
                  <div class="avatar avatar-sm">
                    <img
                      :src="src"
                      alt="User Image"
                      class="avatar-img rounded-circle"
                    />
                  </div>
                  <div class="user-text">
                    <h6 v-if="estudiante">{{ estudiante.nombres }} {{ estudiante.apellidos }}</h6>
                    <h6 v-else>{{ user ? user.name : "" }}</h6>
                    <!-- <p v-if="estudiante" class="text-muted mb-0">Estudiante</p>
                    <p v-else class="text-muted mb-0">Administrador</p> -->
                  </div>
                </div>
                <router-link
                  v-if="false"
                  class="dropdown-item"
                  to="/student/student-dashboard"
                  ><i class="feather-home me-1"</i> Panel</router-link
                >
                <router-link
                v-if="estudiante"
                  class="dropdown-item"
                  to="/student/student-settings"
                  ><i class="feather-user me-1"></i> Perfil</router-link
                >
                <router-link
                v-if="false"
                  class="dropdown-item"
                  to="/student/setting-student-subscription"
                  ><i class="feather-star me-1"</i> Suscripción</router-link
                >
                <div class="dropdown-item night-mode" v-if="false">
                  <span><i class="feather-moon me-1"></i> Modo nocturno</span>
                  <div class="form-check form-switch check-on m-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="night-mode"
                    />
                  </div>
                </div>
                <button class="dropdown-item" @click="cerrarSesion">
                  <i class="feather-log-out me-1"></i> Cerrar sesión
                </button>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import UserService from "@/service/UserService";
import { useAuth } from "@/stores";

import { ref, onMounted } from "vue";
export default {
  data() {
    return {
      isSticky: false,
      isSidebarOpen: true,
      estudiante: null,
      user: null,
      src: require("@/assets/img/user/avatar.png"),
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.obtenerUsuario();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.auth = useAuth();
    this.userService = new UserService();
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
    obtenerUsuario() {
      this.userService.getUser().then((response) => {
        this.user = response.user;
        if (!response.success) {
          this.src = require("@/assets/img/logo/avatar_papo.png");
          return;
        }
        if (this.user.tipo_usuario === 3) {
          return;
        }
        this.estudiante = response.usuario;

        this.src = response.usuario.foto
          ? response.usuario.foto
          : require("@/assets/img/user/avatar.png");
      });
    },
    cerrarSesion() {
      if (confirm("¿Está seguro de cerrar sesión?")) this.auth.userLogout();
    },
  },
  setup() {
    const darkModeToggle = ref(null);
    const lightModeToggle = ref(null);

    // Function to enable dark mode
    function enableDarkMode() {
      document.documentElement.setAttribute("class", "light dark");
      darkModeToggle.value.classList.remove("activate");
      lightModeToggle.value.classList.add("activate");
      localStorage.setItem("darkMode", "enabled");
    }

    // Function to disable dark mode
    function disableDarkMode() {
      /* document.documentElement.setAttribute("class", "light");
      lightModeToggle.value.classList.remove("activate");
      darkModeToggle.value.classList.add("activate");
      localStorage.removeItem("darkMode"); */
    }

    // Check the current mode on page load
    onMounted(() => {
      const darkMode = localStorage.getItem("darkMode");
      if (darkMode === "enabled") {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    return {
      darkModeToggle,
      lightModeToggle,
      enableDarkMode,
      disableDarkMode,
    };
  },
};
</script>
