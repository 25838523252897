import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class NivelCursoService {
  async getNiveles() {
    return await fetchWrapper.get(`${ruta}/niveles_cursos`);
  }
  async saveNivel(datos) {
    return await fetchWrapper.post(`${ruta}/niveles_cursos`, datos);
  }
  async updateNivel(datos) {
    return await fetchWrapper.put(`${ruta}/niveles_cursos/` + datos.id, datos);
  }
  async deleteNivel(id) {
    return await fetchWrapper.put(`${ruta}/niveles_cursos/eliminar/${id}`);
  }
}
