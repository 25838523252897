import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class EstudianteService {
  async getEstudiantes() {
    return await fetchWrapper.get(`${ruta}/estudiantes`);
  }
  async datosNecesarios() {
    return await fetchWrapper.get(`${ruta}/datNecesary`);
  }
  async guardarEstudiante(estudiante) {
    return await fetchWrapper.post(`${ruta}/createEstudent`, estudiante);
  }
  async updateEstudiante(estudiante) {
    return await fetchWrapper.put(
      `${ruta}/estudiantes/` + estudiante.id,
      estudiante
    );
  }
  async updateContraseña(datos) {
    return await fetchWrapper.post(`${ruta}/updateContrasena`, datos);
  }
  async buscarPais(pais) {
    return await fetchWrapper.post(`${ruta}/buscarPais`, pais);
  }
  async uploadImage(img) {
    return await fetchWrapper.post(`${ruta}/uploadImage/estudiante`, img);
  }
  async activarDesactivar(id) {
    return await fetchWrapper.put(
      `${ruta}/estudiantes/activar/desactivar/${id}`
    );
  }
}
