<template>
  <div>
    <!-- Botón flotante -->
    <div
      class="whatsapp-button"
      :class="{ bouncing: !isChatOpen }"
      @click="toggleChat"
    >
      <!-- Imagen de WhatsApp (papo.png) -->
      <img src="@/assets/img/papo.png" alt="WhatsApp" class="whatsapp-icon" height="84px" width="84px"/>
    </div>

    <!-- Ventana de chat -->
    <transition name="slide-up">
      <div v-if="isChatOpen" class="whatsapp-chat">
        <header class="chat-header">
          <!-- Imagen de WhatsApp (papo.png) en el header -->
          <i class="fab fa-whatsapp"></i>
          <span>Asesor de Cursos</span>
          <button @click="toggleChat" class="close-btn">×</button>
        </header>
        <div class="chat-body">
          <p>¡Hola! 👋</p>
          <p>
            Estamos aquí para ayudarte con cualquier consulta sobre nuestros
            cursos. 😊
          </p>
          <a
            :href="whatsappLink"
            target="_blank"
            rel="noopener noreferrer"
            class="start-chat"
          >
            Iniciar conversación
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChatOpen: false, // Estado para abrir/cerrar el chat
      phoneNumber: "59170920222", // Número de WhatsApp
      message:
        "¡Hola! Estoy interesado en conocer más sobre los cursos que ofrecen. 😊", // Mensaje inicial
    };
  },
  computed: {
    whatsappLink() {
      return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(
        this.message
      )}`;
    },
  },
  methods: {
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
  },
};
</script>

<style scoped>
/* Estilo del botón flotante */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #f7f1ee;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 10;
}
.whatsapp-button:hover {
  transform: scale(1.1);
}

/* Efecto de rebote */
.bouncing {
  animation: bounce 1.5s infinite;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
/* Header del chat */
.chat-header i {
  font-size: 30px; /* Aumenta el tamaño del ícono */
  margin-right: 20px;
}


/* Estilo de la ventana del chat */
.whatsapp-chat {
  position: fixed;
  bottom: 90px;
  right: 20px;
  background: #f7f1ee;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
}

/* Header del chat */
.chat-header {
  background-color: #233269;
  color: #f7f1ee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-header .whatsapp-icon {
  width: 20px; /* Ajusta el tamaño de la imagen */
  height: 20px;
  margin-right: 10px;
}
.i
{
  
}
.chat-header .close-btn {
  background: none;
  border: none;
  color: #f7f1ee;
  font-size: 15px;
  cursor: pointer;
}

/* Cuerpo del chat */
.chat-body {
  padding: 10px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins-Light", sans-serif;
}
.chat-body p {
  margin: 0 0 10px;
  text-align: center;
}
.start-chat {
  background-color: #233269;
  color: #f7f1ee;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease;
}
.start-chat:hover {
  background-color: #233269;
}
/* Header del chat */
.chat-header span {
  font-size: 16px; /* Aumenta el tamaño del texto */
}

/* Animaciones */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
