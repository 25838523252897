<template>
    <page-header v-if="auth.user"></page-header>
    <horizontal-header v-else></horizontal-header>
    <home-banner></home-banner>
    <feature-course></feature-course>
    <opiniones></opiniones>
    <trending-course></trending-course>
    <share-knowledge></share-knowledge>
    <latest-blog></latest-blog>
    <layouts1></layouts1>
    <whatsapp />
</template>
<script>
import { useAuth } from '@/stores';
export default {
  data() {
    return {
      auth: useAuth(),
    };
  },
};
</script>
