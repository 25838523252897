<template>
  <layouts-instructorborder v-if="auth.user"></layouts-instructorborder>
  <horizontal-header v-else></horizontal-header>

  <section style="margin-top: 70px; height: 250px; background-color: #171723">
    <div class="row" style="padding: 20px 0 0 0;">
      <Carousel :items-to-show="2.5" :autoplay="2000" :wrap-around="true" :pauseAutoplayOnHover="true">
        <Slide key="0">
          <div class="img-slide">
            <img
              src="@/assets/img/jaldin_movimiento2.gif"
              alt=""
              class="img-fluid zoom-image"
            />
          </div>
        </Slide>
        <Slide key="1">
          <div class="img-slide">
            <img
              src="@/assets/img/jaldin_movimiento2.gif"
              alt=""
              class="img-fluid zoom-image"
            />
          </div>
        </Slide>
        <Slide key="2">
          <div class="img-slide">
            <img
              src="@/assets/img/jaldin_movimiento2.gif"
              alt=""
              class="img-fluid zoom-image"
            />
          </div>
        </Slide>
        <Slide key="3">
          <div class="img-slide">
            <img
              src="@/assets/img/jaldin_movimiento2.gif"
              alt=""
              class="img-fluid zoom-image"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
  <section class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="about-text">
            <h1>Sobre Nosotros</h1>
            <p>
              En <strong>Papo Network</strong> creemos que aprender T.I. es mucho
              más que acumular conocimientos: es una experiencia que transforma
              vidas. Nos dedicamos a enseñar ciberseguridad, redes y tecnología
              con pasión, de manera accesible y práctica, para que cualquiera
              pueda dar el siguiente gran paso en su carrera.
            </p>

            <p>
              Nuestra meta es simple: formar una comunidad que comparta, crezca
              y viva la tecnología como nosotros. Si estás listo para aprender,
              aquí encontrarás todo lo que necesitas.
            </p>

            <p>
              <strong>Papo Network</strong>: donde la tecnología se vive con
              pasión.
            </p>

            <h2>Nuestra Historia</h2>
            <p>
              Este proyecto es el resultado de la visión y esfuerzo compartido
              entre sus cofundadores: <strong>Luis</strong>,
              <strong>Iver</strong>, <strong>Carlos</strong> y quien les
              escribe, <strong>Rubén Jaldin</strong>. Juntos, hemos superado
              desafíos, trabajando unidos con compromiso y dedicación, para
              construir un espacio donde el conocimiento y la tecnología estén
              al alcance de todos.
            </p>
          </div>
        </div>
        <div class="col-md-5 about-image">
          <div class="particle-container">
            <img
              src="@/assets/img/skil-01.png"
              alt="Ejemplo"
              class="zoom-image"
            />
            <div class="particles"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <layouts1></layouts1>
</template>

<script>
import { useAuth } from "@/stores"; // Importar el store de autenticación
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

export default {
  name: "SobreNosotros",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      auth: useAuth(), // Instancia del store de autenticación
    };
  },
};
</script>

<style scoped>
.about-text {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 40px;
  background: linear-gradient(0.25turn, #171723, #34344f, #171723);
  border-radius: 10px;
  filter: drop-shadow(#201d64 0px 0px 10px);
}

.particle-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.particle-container img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(
    circle at right,
    rgba(115, 203, 214),
    transparent
  );
  animation: particleAnimation 8s infinite;
}

h1 {
  color: #73cbd6;
  margin-bottom: 20px;
}

h2 {
  color: #8475b6;
  margin-top: 20px;
}

p {
  color: #f7f1ee;
}

strong {
  color: #73cbd6;
}

/* Keyframes para animar las partículas */
@keyframes particleAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Efecto de zoom */
.zoom-image {
  transition: transform 0.3s ease;
}

.zoom-image:hover {
  transform: scale(1.1);
}

.img-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
}
</style>
