<template>
  <div class="loading-container" v-if="loading">
    <ProgressSpinner
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="transparent"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
  </div>
  <div v-else>
    <page-header></page-header>
    <!-- Nuevo Curso -->
    <section class="page-content course-sec mt-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!--  <div class="page-title">
              <h2 class="titulo-h5">
                <i class="fas fa-plus"></i> Agregar Nuevo Curso
              </h2>
            </div> -->
            <div class="card estilo-card-curso">
              <!-- Asistente del Curso -->
              <div class="widget-set">
                <div class="widget-setcount">
                  <ul v-show="pasoActual === 1">
                    <li class="progress-active">
                      <p><span></span> Información Básica</p>
                    </li>
                    <li>
                      <p><span></span> Medios del Curso</p>
                    </li>
                    <li>
                      <p><span></span> Lecciones</p>
                    </li>
                  </ul>
                  <ul v-show="pasoActual === 2">
                    <li class="progress-activated">
                      <p><span></span> Información Básica</p>
                    </li>
                    <li class="progress-active">
                      <p><span></span> Medios del Curso</p>
                    </li>
                    <li>
                      <p><span></span> Lecciones</p>
                    </li>
                  </ul>
                  <ul v-show="pasoActual === 3">
                    <li class="progress-activated">
                      <p><span></span> Información Básica</p>
                    </li>
                    <li class="progress-activated">
                      <p><span></span> Medios del Curso</p>
                    </li>
                    <li class="progress-active">
                      <p><span></span> Lecciones</p>
                    </li>
                  </ul>
                </div>
                <div class="widget-content multistep-form">
                  <fieldset id="first" v-if="pasoActual === 1">
                    <div class="add-course-info">
                      <div class="add-course-form">
                        <div class="row">
                          <form action="#" class="col-md-12">
                            <div class="row">
                              <div class="form-group mb-2 col-md-6">
                                <strong class="strong-titulo-blanco"
                                  >Título del Curso:</strong
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Título del Curso"
                                  v-model="titulo"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.nombre"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.nombre[0] }}</Message
                                >
                              </div>
                              <div class="form-group mb-2 col-md-6">
                                <strong class="strong-titulo-blanco"
                                  >Instructor del Curso:</strong
                                >
                                <vue-select
                                  :options="instructores"
                                  placeholder="Seleccione..."
                                  v-model="selectedInstructor"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.instructor_id"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.instructor_id[0] }}</Message
                                >
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group mb-2 col-md-4">
                                <strong class="strong-titulo-blanco"
                                  >Categoría del Curso:</strong
                                >
                                <vue-select
                                  :options="categorias"
                                  placeholder="Seleccione..."
                                  v-model="categoriaSelected"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.categoria_id"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.categoria_id[0] }}</Message
                                >
                              </div>
                              <div class="form-group mb-2 col-md-2">
                                <strong class="strong-titulo-blanco"
                                  >Nivel del Curso:</strong
                                >
                                <vue-select
                                  :options="niveles_cursos"
                                  placeholder="Seleccione..."
                                  v-model="nivelCursoSelected"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.nivel_curso_id"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.nivel_curso_id[0] }}</Message
                                >
                              </div>
                              <div class="form-group mb-2 col-md-3">
                                <strong class="strong-titulo-blanco"
                                  >Certificación:</strong
                                >
                                <vue-select
                                  :options="certificaciones"
                                  placeholder="Seleccione..."
                                  v-model="certificacionSelected"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.certificacion_id"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.certificacion_id[0] }}</Message
                                >
                              </div>
                              <div class="form-group mb-2 col-md-3">
                                <strong class="strong-titulo-blanco"
                                  >habilidades:</strong
                                >
                                <MultiSelect
                                  class="form-control"
                                  v-model="habilidadSelected"
                                  :options="habilidades"
                                  optionLabel="text"
                                  optionValue="value"
                                  placeholder="Selecciona..."
                                  filter
                                  size="small"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div
                                :class="`form-group mb-2 ${
                                  promociones.length > 0
                                    ? 'col-md-6'
                                    : 'col-md-10'
                                }`"
                              >
                                <strong class="strong-titulo-blanco"
                                  >Descripci&oacute;n Corta del Curso:</strong
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Descripción Corta del Curso"
                                  v-model="descripcion_corta"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.descripcion_corta"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.descripcion_corta[0] }}</Message
                                >
                              </div>
                              <div
                                class="form-group mb-2 col-md-4"
                                v-if="promociones.length > 0"
                              >
                                <strong class="strong-titulo-blanco"
                                  >Promociones:</strong
                                >
                                <MultiSelect
                                  class="form-control"
                                  v-model="promocionSelected"
                                  :options="promociones"
                                  optionLabel="text"
                                  optionValue="value"
                                  placeholder="Selecciona..."
                                  filter
                                  size="small"
                                />
                              </div>
                              <div class="form-group mb-2 col-md-2">
                                <strong class="strong-titulo-blanco"
                                  >Precio del Curso:</strong
                                >
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder="Precio del Curso"
                                  v-model="precio"
                                />
                                <Message
                                  class="text-danger"
                                  v-if="errors.precio"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.precio[0] }}</Message
                                >
                              </div>
                            </div>
                            <div class="form-group mb-0 col-md-12">
                              <strong class="strong-titulo-blanco"
                                >Descripci&oacute;n Detallada del Curso:</strong
                              >
                              <div id="editor" class="editor-block">
                                <ckeditor
                                  :editor="editor"
                                  v-model="editorData"
                                  :config="editorConfig"
                                ></ckeditor>
                                <Message
                                  class="text-danger"
                                  v-if="errors.descripcion"
                                  severity="error"
                                  variant="simple"
                                  >{{ errors.descripcion[0] }}</Message
                                >
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="widget-btn justify-content-end">
                        <!-- <a class="btn btn-black"
                          ><i class="fas fa-arrow-left"></i> Atrás</a
                        > -->
                        <a
                          class="btn btn-cuadrado"
                          @click="nextStep"
                          v-if="pasoActual === 1"
                        >
                          <i class="fas fa-arrow-right"></i> Continuar</a
                        >
                      </div>
                    </div>
                  </fieldset>
                  <fieldset
                    id="first"
                    class="field-card"
                    v-if="pasoActual === 2"
                  >
                    <div class="add-course-info">
                      <div class="add-course-form">
                        <form action="#">
                          <!-- Imagen de portada -->
                          <div class="input-block">
                            <strong class="strong-titulo-blanco"
                              >Imagen de portada del curso</strong
                            >
                            <input
                              type="file"
                              class="form-control"
                              accept="image/*"
                              @change="previewImage"
                            />
                            <div v-if="imageUrl" class="preview-box">
                              <img
                                :src="imageUrl"
                                alt="Previsualización de la imagen"
                                class="image-preview"
                              />
                            </div>
                            <Message
                              class="text-danger"
                              v-if="errors.imagen"
                              severity="error"
                              variant="simple"
                              >{{ errors.imagen[0] }}</Message
                            >
                          </div>

                          <!-- Video del curso -->
                          <div class="input-block">
                            <strong class="strong-titulo-blanco"
                              >URL del video</strong
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="URL del video"
                              v-model="videoUrl"
                            />
                            <div v-if="videoUrl" class="preview-box">
                              <iframe
                                v-if="isYoutubeLink(videoUrl)"
                                :src="getYoutubeEmbedUrl(videoUrl)"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                class="video-preview"
                              ></iframe>
                              <p v-else>Ingresa un enlace válido de YouTube.</p>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="widget-btn">
                        <a
                          class="btn btn-black prev_btn"
                          @click="previousStep"
                          :disabled="pasoActual === 1"
                          ><i class="fas fa-arrow-left"></i> Anterior</a
                        >
                        <a
                          class="btn btn-cuadrado"
                          @click="nextStep"
                          :disabled="
                            pasoActual === totalDePasos ||
                            !imageUrl ||
                            !videoUrl
                          "
                        >
                          <i class="fas fa-arrow-right"></i> Continuar</a
                        >
                      </div>
                    </div>
                  </fieldset>

                  <fieldset
                    id="first"
                    class="field-card"
                    v-if="pasoActual === 3"
                  >
                    <div class="add-course-info">
                      <!-- Botón para agregar una nueva sección -->
                      <div class="add-course-section">
                        <button class="btn" @click="addModulos">
                          Agregar Módulo
                        </button>
                      </div>

                      <!-- Renderiza cada sección y sus lecciones -->
                      <div
                        class="add-course-form"
                        v-for="(modulo, moduloIndex) in modulos"
                        :key="modulo.id"
                      >
                        <div class="curriculum-grid">
                          <div
                            class="curriculum-head"
                            style="display: flex; align-items: end; gap: 10px"
                          >
                            <div
                              style="
                                display: flex;
                                flex-direction: column;
                                width: 75%;
                              "
                            >
                              <span> Módulo {{ moduloIndex + 1 }}: </span>
                              <input
                                type="text"
                                v-model="modulo.nombre"
                                placeholder="Nombre del Modulo"
                                class="form-control"
                              />
                              <Message
                                class="text-danger"
                                v-if="errors['modulos.0.nombre']"
                                severity="error"
                                variant="simple"
                                >{{ errors["modulos.0.nombre"][0] }}</Message
                              >
                            </div>
                            <input
                              type="text"
                              v-if="false"
                              v-model="modulo.descripcion"
                              placeholder="Descripción del Modulo"
                              class="form-control"
                              style="width: 35%"
                            />
                            <div>
                              <button
                                class="btn btn-eliminar"
                                @click="deleteModulos(modulo)"
                              >
                                Eliminar
                              </button>
                              <button
                                class="btn btn-blue-color"
                                @click="addLesson(modulo)"
                              >
                                Agregar Lección
                              </button>
                            </div>
                          </div>

                          <!-- Renderiza cada lección dentro de la sección -->
                          <div
                            class="faq-grid"
                            v-for="(tema, temaIndex) in modulo.temas"
                            :key="tema.id"
                          >
                            <!-- Información de la Lección -->
                            <div class="faq-header">
                              <a
                                class="collapsed faq-collapse"
                                data-bs-toggle="collapse"
                                :href="'#collapse' + moduloIndex + temaIndex"
                              >
                                <i class="fas fa-align-justify"></i>
                                <div class="input-container">
                                  <input
                                    type="text"
                                    v-model="tema.nombre"
                                    placeholder="Nombre del Tema"
                                    class="form-control input-nombre"
                                    @click.stop
                                  />
                                  <Message
                                    class="text-danger"
                                    v-if="errors[`modulos.0.temas.0.nombre`]"
                                    severity="error"
                                    variant="simple"
                                    >{{
                                      errors[`modulos.0.temas.0.nombre`][0]
                                    }}</Message
                                  >
                                </div>
                                <div class="input-container" v-if="false">
                                  <input
                                    type="text"
                                    v-model="tema.descripcion"
                                    placeholder="Descripción del Tema"
                                    class="form-control input-descripcion"
                                    @click.stop
                                  />
                                </div>
                              </a>
                              <div class="faq-right curriculum-head">
                                <button
                                  class="btn btn-blue-color"
                                  @click="agregarRecurso(tema)"
                                >
                                  Agregar Recurso
                                </button>
                                <button
                                  @click="deleteLesson(modulo, tema)"
                                  class="btn-eliminar"
                                >
                                  🗑️
                                </button>
                              </div>
                            </div>

                            <div
                              :id="'collapse' + moduloIndex + temaIndex"
                              class="collapse"
                              :data-bs-parent="
                                '#accordion' + moduloIndex + temaIndex
                              "
                            >
                              <div class="faq-body">
                                <div class="row">
                                  <!-- Etiqueta de Recursos -->
                                  <div class="col-auto">
                                    <label>Recursos</label>
                                  </div>
                                </div>

                                <!-- Lista de recursos -->
                                <div
                                  class="resource-list curriculum-head row"
                                  v-for="(
                                    recurso, recursoIndex
                                  ) in tema.recursos"
                                  :key="recurso.id"
                                >
                                  <!-- Tipo de archivo -->
                                  <div class="col-md-2">
                                    <span class="add-course-label"
                                      >Tipo de Archivo:</span
                                    >
                                    <vue-select
                                      :options="tipoRecursos"
                                      placeholder="Seleccione..."
                                      v-model="recurso.tipoRecurso"
                                    />
                                  </div>

                                  <!-- Archivo del recurso (visible solo si tipoRecurso == 1) -->
                                  <div
                                    class="col-md-7"
                                    v-if="recurso.tipoRecurso == 1"
                                  >
                                    <span class="add-course-label"
                                      >Archivo del Recurso</span
                                    >
                                    <input
                                      type="file"
                                      class="form-control"
                                      @change="
                                        previewFile(
                                          $event,
                                          moduloIndex,
                                          temaIndex,
                                          recurso.id
                                        )
                                      "
                                    />
                                    <Message
                                      class="text-danger"
                                      v-if="
                                        errors[`modulos.0.temas.0.recursos`]
                                      "
                                      severity="error"
                                      variant="simple"
                                      >{{
                                        errors[`modulos.0.temas.0.recursos`][0]
                                      }}</Message
                                    >
                                  </div>

                                  <!-- URL del video y duración del video (visible solo si tipoRecurso == 2) -->
                                  <div
                                    class="col-md-7"
                                    v-if="recurso.tipoRecurso == 2"
                                  >
                                    <div class="row">
                                      <!-- URL del video -->
                                      <div class="col-md-8">
                                        <span class="add-course-label"
                                          >URL del video</span
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="URL del video"
                                          v-model="recurso.url"
                                        />
                                        <Message
                                          class="text-danger"
                                          v-if="
                                            errors[
                                              `modulos.${Index}.temas.${temaIndex}.recursos.${recursoIndex}.url`
                                            ]
                                          "
                                          severity="error"
                                          variant="simple"
                                          >{{
                                            errors[
                                              `modulos.${Index}.temas.${temaIndex}.recursos.${recursoIndex}.url`
                                            ][0]
                                          }}</Message
                                        >
                                        <div
                                          v-if="recurso.url"
                                          class="preview-box"
                                        >
                                          <iframe
                                            v-if="isYoutubeLink(recurso.url)"
                                            :src="
                                              getYoutubeEmbedUrl(recurso.url)
                                            "
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            class="video-preview"
                                          ></iframe>
                                          <p v-else>
                                            Ingresa un enlace válido de YouTube.
                                          </p>
                                        </div>
                                      </div>

                                      <!-- Duración del video -->
                                      <div class="col-md-4">
                                        <span class="add-course-label"
                                          >Duración del Video</span
                                        >
                                        <input
                                          type="text"
                                          v-model="recurso.duracion"
                                          placeholder="Duración del Video"
                                          class="form-control"
                                        />
                                        <Message
                                          class="text-danger"
                                          v-if="
                                            errors[
                                              `modulos.0.temas.0.recursos.0.duracion`
                                            ]
                                          "
                                          severity="error"
                                          variant="simple"
                                          >{{
                                            errors[
                                              `modulos.0.temas.0.recursos.0.duracion`
                                            ][0]
                                          }}</Message
                                        >
                                      </div>
                                    </div>
                                  </div>

                                  <!-- Botón para eliminar el recurso -->
                                  <div class="col-auto d-flex align-items-end">
                                    <button
                                      class="btn-red"
                                      @click="deleteResource(tema, recurso)"
                                    >
                                      Eliminar Recurso
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Botones de navegación -->
                      <div class="widget-btn">
                        <button
                          class="btn btn-black prev_btn"
                          @click="previousStep"
                          :disabled="pasoActual === 2"
                        >
                          <i class="fas fa-arrow-left"></i>
                          Anterior
                        </button>
                        <a
                          class="btn btn-success-dark"
                          @click="editarCurso"
                          v-if="id"
                        >
                          <i class="fas fa-edit"></i> Editar Curso</a
                        >
                        <a
                          class="btn btn-cuadrado"
                          @click="guardarCurso"
                          v-else
                        >
                          <i class="fas fa-save"></i> Guardar Curso</a
                        >
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <!-- /Course Wizard -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /New Course -->

    <layouts1></layouts1>
  </div>
</template>
<script>
import Vue3TagsInput from "vue3-tags-input";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CursoService from "@/service/CursoService";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    Vue3TagsInput,
    Message,
    ProgressSpinner,
    MultiSelect,
  },
  data() {
    return {
      editor: ClassicEditor,
      titulo: "",
      editorData: "Descripcion",
      categoriaSelected: null,
      selectedInstructor: null,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
          ],
        },
      },
      tipoRecursos: [
        {
          id: 1,
          nombre: "Documento",
        },
        {
          id: 2,
          nombre: "Video",
        },
      ],
      user: null,
      tags: ["jquery", "bootstrap"],
      categorias: [],
      instructores: [],
      Level: ["Level 01", "Level 02", "Level 03", "Level 04"],
      pasoActual: 1,
      totalDePasos: 5,
      content: "Descripcion",
      imageUrl: null, // Imagen cargada para previsualizar
      imageBase64: null,
      videoUrl: "", // URL del video
      precio: 0.0,
      duracion: "00:00:00",
      modulos: [
        {
          id: 1, // Asume un ID incremental
          nombre: "", // Nombre de la sección vacío para que el usuario lo ingrese
          descripcion: "",
          orden: 1,
          duracion: "00:00:00",
          temas: [
            {
              id: 1, // Asume un ID incremental
              nombre: "", // Nombre de la sección vacío para que el usuario lo ingrese
              descripcion: "",
              orden: 1,
              duracion: "00:00:00",
              recursos: [],
            },
          ],
        },
      ],
      descripcion_corta: "",
      niveles_cursos: [],
      certificaciones: [],
      nivelCursoSelected: null,
      certificacionSelected: null,
      errors: [],
      loading: false,
      habilidades: [],
      promociones: [],
      habilidadSelected: null,
      promocionSelected: null,
    };
  },
  cursoService: null,

  created() {
    this.cursoService = new CursoService();
  },
  mounted() {
    /*  this.obtenerCategorias();
    this.obtenerInstructores(); */
    this.datosNecessariosCurso();
    this.tipodeRecursos();
    this.actualizarDuracion();
    if (this.id) {
      this.obtenercursoEditar();
    }
  },
  computed: {
    id() {
      return this.$route.params.id || null;
    },
  },
  methods: {
    obtenercursoEditar() {
      this.cursoService.detalleCurso(this.id).then((response) => {
        this.titulo = response.curso.nombre;
        this.selectedInstructor = response.curso.instructor_id;
        this.categoriaSelected = response.curso.categoria_id;
        this.nivelCursoSelected = response.curso.nivel_curso_id;
        this.certificacionSelected = response.curso.certificacion_id;
        this.habilidadSelected = response.habilidades;
        this.descripcion_corta = response.curso.descripcion_corta;
        this.promocionSelected = response.promociones;
        this.precio = response.curso.precio;
        this.editorData = response.curso.descripcion;
        this.imageUrl = response.curso.imagen;
        this.videoUrl = response.curso.video_promocional;
        this.modulos = response.modulos;
      });
      console.log(this.modulos);
    },
    datosNecessariosCurso() {
      this.cursoService.datosNecessariosCurso().then((response) => {
        this.categorias = response.categorias || [];
        for (let i = 0; i < this.categorias.length; i++) {
          this.categorias[i].value = this.categorias[i].id;
          this.categorias[i].text = this.categorias[i].nombre;
        }
        this.instructores = response.instructores || [];
        for (let i = 0; i < this.instructores.length; i++) {
          this.instructores[i].value = this.instructores[i].id;
          this.instructores[i].text = this.instructores[i].nombre_completo;
        }

        this.niveles_cursos = response.niveles_cursos || [];
        for (let i = 0; i < this.niveles_cursos.length; i++) {
          this.niveles_cursos[i].value = this.niveles_cursos[i].id;
          this.niveles_cursos[i].text = this.niveles_cursos[i].nombre;
        }

        this.certificaciones = response.certificaciones || [];
        for (let i = 0; i < this.certificaciones.length; i++) {
          this.certificaciones[i].value = this.certificaciones[i].id;
          this.certificaciones[i].text = this.certificaciones[i].nombre;
        }

        this.habilidades = response.habilidades || [];
        for (let i = 0; i < this.habilidades.length; i++) {
          this.habilidades[i].value = this.habilidades[i].id;
          this.habilidades[i].text = this.habilidades[i].nombre;
        }

        this.promociones = response.promociones || [];
        for (let i = 0; i < this.promociones.length; i++) {
          this.promociones[i].value = this.promociones[i].id;
          this.promociones[i].text = this.promociones[i].nombre;
        }
      });
    },
    editarCurso(event) {
      this.actualizarDuracion();
      event.preventDefault();

      let data = {
        id: this.id,
        categoria_id: this.categoriaSelected,
        instructor_id: this.selectedInstructor,
        nombre: this.titulo,
        descripcion: this.editorData,
        imagen: this.imageBase64 ?? this.imageUrl,
        video_promocional: this.videoUrl,
        precio: this.precio,
        duracion: this.duracion,
        modulos: this.modulos,
        descripcion_corta: this.descripcion_corta,
        nivel_curso_id: this.nivelCursoSelected,
        certificacion_id: this.certificacionSelected,
        habilidades: this.habilidadSelected,
        promociones: this.promocionSelected,
      };
      this.loading = true;
      this.cursoService.editarCurso(data).then((response) => {
        try {
          if (response.errors) {
            this.loading = false;
            this.errors = response.errors;
            this.pasoActual = 1;
            return;
          }
          if (response.success) {
            this.loading = false;
            this.$message.success(response.message);
            this.errors = [];
            this.limpiar();
            this.$router.push("/course/course-grid");
          } else {
            this.loading = false;
            this.$message.error(response.message);
          }
        } catch (error) {
          this.loading = false;
          this.$message.error(response.message);
        }
      });
    },
    guardarCurso(event) {
      this.actualizarDuracion();
      event.preventDefault();

      let data = {
        categoria_id: this.categoriaSelected,
        instructor_id: this.selectedInstructor,
        nombre: this.titulo,
        descripcion: this.editorData,
        imagen: this.imageBase64,
        video_promocional: this.videoUrl,
        precio: this.precio,
        duracion: this.duracion,
        modulos: this.modulos,
        descripcion_corta: this.descripcion_corta,
        nivel_curso_id: this.nivelCursoSelected,
        certificacion_id: this.certificacionSelected,
        habilidades: this.habilidadSelected,
        promociones: this.promocionSelected,
      };
      this.loading = true;
      this.cursoService.guardarCursoNuevo(data).then((response) => {
        try {
          if (response.errors) {
            this.loading = false;
            this.errors = response.errors;
            console.log(this.errors["modulos.0.nombre"]);
            this.pasoActual = 1;
            return;
          }
          if (response.success) {
            this.loading = false;
            this.$message.success(response.message);
            this.errors = [];
            this.limpiar();
            this.datosNecessariosCurso();
          } else {
            this.loading = false;
            this.$message.error(response.message);
          }
        } catch (error) {
          this.loading = false;
          this.$message.error(response.message);
        }
      });
      this.loading = false;
    },
    limpiar() {
      (this.titulo = ""),
        (this.editorData = "Descripcion"),
        (this.categoriaSelected = null),
        (this.selectedInstructor = null),
        (this.nivelCursoSelected = null),
        (this.certificacionSelected = null),
        (this.habilidadSelected = null),
        (this.promocionSelected = null),
        (this.categorias = []),
        (this.instructores = []),
        (this.niveles_cursos = []),
        (this.certificaciones = []),
        (this.pasoActual = 1),
        (this.content = "Descripcion"),
        (this.imageUrl = null), // Imagen cargada para previsualizar
        (this.imageBase64 = null),
        (this.videoUrl = ""), // URL del video
        (this.precio = 0.0),
        (this.duracion = "00:00:00"),
        (this.modulos = [
          {
            id: 1, // Asume un ID incremental
            nombre: "", // Nombre de la sección vacío para que el usuario lo ingrese
            descripcion: "",
            orden: 1,
            duracion: "00:00:00",
            temas: [
              {
                id: 1, // Asume un ID incremental
                nombre: "", // Nombre de la sección vacío para que el usuario lo ingrese
                descripcion: "",
                orden: 1,
                duracion: "00:00:00",
                recursos: [],
              },
            ],
          },
        ]);
    },
    addModulos() {
      // Modulos
      // const newSectionId = this.modulos.length + 1;
      this.modulos.push({
        id: this.modulos.length + 1,
        nombre: "",
        descripcion: "",
        orden: this.modulos.length + 1,
        temas: [],
      });
    },
    deleteModulos(modulo) {
      // Encuentra el índice de la lección a eliminar
      const moduloIndex = this.modulos.indexOf(modulo);

      // Elimina el módulo si el índice es válido
      if (moduloIndex > -1) {
        this.modulos.splice(moduloIndex, 1);

        // Reasigna el campo `orden` en cada módulo después de la eliminación
        this.modulos = this.modulos.map((modulo, index) => {
          return {
            ...modulo,
            orden: index + 1, // Asigna el nuevo número de orden, comenzando desde 1
          };
        });
      }
    },
    addLesson(modulo) {
      //Temas
      const newLessonId = modulo.temas.length + 1;
      const newLessonOrden = modulo.temas.length + 1;
      modulo.temas.push({
        id: newLessonId,
        nombre: `Lección ${newLessonId}`,
        descripcion: "",
        orden: newLessonOrden,
        recursos: [],
      });
    },
    editLesson(modulo, temas) {
      // Aquí podrías implementar la funcionalidad para editar una lección
      const newName = prompt("Edita el nombre de la lección:", temas.nombre);
      const newDescripcion = prompt(
        "Edita descripcion de la lección:",
        temas.descripcion
      );

      if (newName || newDescripcion) {
        temas.nombre = newName;
        temas.descripcion = newDescripcion;
      }
    },
    deleteLesson(modulo, tema) {
      // Encuentra el índice de la lección a eliminar
      const temaIndex = modulo.temas.indexOf(tema);

      // Elimina la lección del array
      if (temaIndex > -1) {
        modulo.temas.splice(temaIndex, 1);

        // Reordena el campo `orden` y reemplaza cualquier duplicado
        modulo.temas.forEach((tema, index) => {
          tema.orden = index + 1;
        });

        // Normaliza los campos para eliminar duplicados
        this.normalizeLessons(modulo);
      }
    },
    normalizeLessons(modulo) {
      modulo.temas = modulo.temas.map((tema) => {
        // Reemplaza 'order' por 'orden' y elimina el duplicado
        const { order, ...otherFields } = tema;

        return {
          ...otherFields,
          orden: order !== undefined ? order : tema.orden, // Usa 'order' si existe, de lo contrario conserva 'orden'
        };
      });
    },
    agregarRecurso(temas) {
      //Recursos
      const newRecursosId = temas.recursos.length + 1;
      temas.recursos.push({
        id: newRecursosId,
        nombre: temas.nombre,
        duracion: "00:00:00",
        fileUrl: null,
        fileBase64: null,
        tipoRecurso: 0,
        url: null,
      });
    },
    deleteResource(tema, resource) {
      // Encuentra el índice del recurso a eliminar
      const resourceIndex = tema.recursos.indexOf(resource);

      // Elimina el recurso del array
      if (resourceIndex > -1) {
        tema.recursos.splice(resourceIndex, 1);

        // Reordena el campo `orden` de los recursos restantes
        tema.recursos.forEach((recurso, index) => {
          recurso.orden = index + 1;
        });
      }
    },
    isYoutubeLink(url) {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      return youtubeRegex.test(url);
    },
    getYoutubeEmbedUrl(url) {
      const videoId = url.split("v=")[1] || url.split("/").pop();
      return `https://www.youtube.com/embed/${videoId}`;
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageUrl = URL.createObjectURL(file); // Crea una URL para la imagen cargada
        this.convertToBase64(file);
      } else {
        this.imageUrl = null; // Restablece si no hay archivo
        this.imageBase64 = null; // Restablece el Base64
      }
    },
    previewFile(event, moduloIndex, temaIndex, recursoId) {
      const file = event.target.files[0];
      //console.log(file);

      // Verifica si el módulo y la lección existen antes de intentar acceder
      if (
        this.modulos[moduloIndex] &&
        this.modulos[moduloIndex].temas &&
        this.modulos[moduloIndex].temas[temaIndex] &&
        this.modulos[moduloIndex].temas[temaIndex].recursos
      ) {
        // Encuentra el recurso específico dentro del arreglo
        const recurso = this.modulos[moduloIndex].temas[
          temaIndex
        ].recursos.find((r) => r.id === recursoId);

        if (recurso && file) {
          if (file.type === "application/pdf" || file.type === "text/plain") {
            recurso.fileUrl = URL.createObjectURL(file);
            this.convertToBase64Recursos(file, recurso);
          } else {
            alert("Solo se permiten archivos PDF o TXT");
            this.resetFilePreview(recurso);
          }
        } else {
          console.error("No se pudo encontrar el recurso especificado.");
        }
      } else {
        console.error("El módulo, la lección o los recursos no existen.");
      }
    },
    tipodeRecursos() {
      for (let i = 0; i < this.tipoRecursos.length; i++) {
        this.tipoRecursos[i].value = this.tipoRecursos[i].id;
        this.tipoRecursos[i].text = this.tipoRecursos[i].nombre;
      }
    },

    async obtenerBase64() {
      try {
        this.imageUrl = await this.convertirBlobABase64();
      } catch (error) {
        console.error("Error al convertir el blob:", error);
      }
    },
    // Método para convertir el archivo a Base64
    convertToBase64(file) {
      const reader = new FileReader();

      // Asegúrate de que la conversión esté completa antes de acceder al resultado
      reader.onload = () => {
        // Cuando la lectura termine, el resultado estará completo
        this.imageBase64 = reader.result
          .replace("data:", "")
          .replace(/^.+,/, ""); // Solo la parte base64
      };

      reader.onerror = (error) => {
        console.error("Error al leer el archivo:", error);
      };

      // Inicia la lectura del archivo y lo convierte a Base64
      reader.readAsDataURL(file); // Lee el archivo y lo convierte a Base64
    },
    convertToBase64Recursos(file, recurso) {
      const reader = new FileReader();
      reader.onload = (e) => {
        recurso.fileBase64 = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, ""); // Asigna el archivo en Base64 al recurso específico
      };
      reader.readAsDataURL(file);
    },
    convertirATiempoEnSegundos(tiempo) {
      if (!tiempo || typeof tiempo !== "string") {
        // Retorna 0 si el tiempo no está definido o no es una cadena de texto
        return 0;
      }

      const [horas, minutos, segundos] = tiempo.split(":").map(Number);
      return (horas || 0) * 3600 + (minutos || 0) * 60 + (segundos || 0);
    },

    // Función para convertir segundos a formato HH:MM:SS
    convertirASegundosATiempo(segundos) {
      const horas = Math.floor(segundos / 3600)
        .toString()
        .padStart(2, "0");
      const minutos = Math.floor((segundos % 3600) / 60)
        .toString()
        .padStart(2, "0");
      const seg = (segundos % 60).toString().padStart(2, "0");
      return `${horas}:${minutos}:${seg}`;
    },
    actualizarDuracion() {
      // Variable para almacenar la duración total del curso
      let duracionTotalCurso = 0;

      // Recorre los módulos
      this.modulos.forEach((modulo) => {
        let duracionTotalModulo = 0;

        // Recorre las lecciones dentro de cada módulo
        modulo.temas.forEach((tema) => {
          let duracionTotalLeccion = 0;

          // Suma las duraciones de todos los recursos en la lección
          tema.recursos.forEach((recurso) => {
            duracionTotalLeccion += this.convertirATiempoEnSegundos(
              recurso.duracion
            );
          });

          // Actualiza la duración de la lección en formato HH:MM:SS
          tema.duracion = this.convertirASegundosATiempo(duracionTotalLeccion);

          // Acumula la duración de la lección para el total del módulo
          duracionTotalModulo += duracionTotalLeccion;
        });

        // Actualiza la duración del módulo en formato HH:MM:SS
        modulo.duracion = this.convertirASegundosATiempo(duracionTotalModulo);

        // Acumula la duración del módulo para el total del curso
        duracionTotalCurso += duracionTotalModulo;
      });

      // Almacena la duración total del curso en formato HH:MM:SS
      this.duracion = this.convertirASegundosATiempo(duracionTotalCurso);
    },
    resetFilePreview(recurso) {
      if (recurso) {
        recurso.fileUrl = null;
        recurso.fileBase64 = null;
      }
    },
    nextStep() {
      if (this.pasoActual < this.totalDePasos) {
        this.pasoActual++;
      }
    },
    previousStep() {
      if (this.pasoActual > 1) {
        this.pasoActual--;
      }
    },
  },
};
</script>
<style>
.preview-box {
  margin-top: 10px;
}

.image-preview {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-preview {
  width: 100%;
  height: 315px; /* Ajusta la altura según tus necesidades */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.ck.ck-editor__editable {
  min-height: 200px;
  max-height: 400px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Asegura que esté centrado verticalmente */
  text-align: center;
}
.add-course-section {
  padding: 0 20px 2px !important;
  display: flex;
  justify-content: flex-end;
}
.add-course-section .btn {
  background: #14123b !important;
  color: #fff !important;
}
.add-course-section .btn:hover {
  background: #14123b !important;
  color: #73cbd6 !important;
}
.btn-blue-color {
  background: #233269 !important;
  color: #fff !important;
  border-color: #233269 !important;
}
.btn-blue-color:hover {
  background: #14123b !important;
  color: #73cbd6 !important;
}
.btn-eliminar {
  max-height: 40px;
  color: #ff4667 !important;
  border-color: #ff4667 !important;
}
.btn-eliminar:hover {
  background: #ff4667 !important;
  color: #fff !important;
}
</style>
