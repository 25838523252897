<template>
  <page-header></page-header>
  <hr />
  <!-- Page Wrapper -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!--           v-for="instructor in paginatedInstructors"
 -->
        <div class="col-xl-12 col-lg-12">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>
                  <i
                    class="pi pi-users"
                    style="font-size: 20px; font-weight: bold"
                  >
                  </i>
                  LISTA DE INSTRUCTORES :
                  <router-link class="btn-new-user" to="/register-step-one">
                    <i
                      class="pi pi-user-plus"
                      style="font-size: 20px; font-weight: bold"
                    />
                  </router-link>
                </h3>
              </div>
              <div class="checkout-form">
                <!-- Contenido de Pestañas -->
                <div class="tab-content">
                  <div class="tab-pane show active">
                    <div class="table-responsive custom-table">
                      <table class="table table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>NOMBRE</th>
                            <th>EMAIL</th>
                            <th>TELEFONO</th>
                            <th>PAIS</th>
                            <th>DIRECCION</th>
                            <th>REALIZADO POR</th>
                            <th>ESTADO</th>
                            <th>ACCIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="instructor in paginatedInstructors"
                            :key="instructor.id"
                          >
                            <td>{{ instructor.id }}</td>
                            <td>
                              {{
                                instructor.nombres + " " + instructor.apellidos
                              }}
                            </td>
                            <td>{{ instructor.email }}</td>
                            <td>{{ instructor.telefono }}</td>
                            <td>{{ instructor.pais }}</td>
                            <td>{{ instructor.direccion }}</td>
                            <td>
                              {{ instructor.realizado_por ?? "administrador" }}
                            </td>
                            <td>
                              <span
                                v-if="instructor.estado === 1"
                                class="text-success"
                                >ACTIVO</span
                              >
                              <span
                                v-else
                                class="text-danger"
                                >INACTIVO</span
                              >
                            </td>
                            <td>
                              <button
                                @click="editInstructor(instructor.id)"
                                class="btn-editar"
                              >
                                <i class="pi pi-user-edit"></i>
                              </button>
                              <button
                                :class="instructor.estado === 1 ? 'btn-delete' : 'btn-activar'"
                                @click="activarDesactivar(instructor.id)"
                              >
                                <i :class="instructor.estado === 1 ? 'pi pi-times' : 'pi pi-check'"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /Contenido de Pestañas -->
              </div>
            </div>
          </div>
        </div>

        <Pagination
          :totalPages="totalPages"
          v-model:currentPage="currentPage"
        />
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <layouts1></layouts1>
</template>

<script>
import Pagination from "@/components/lms-pagination.vue";
import InstructorService from "@/service/InstructorService";
import { RouterLink } from "vue-router";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      instructorlist: [],
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.instructorlist.length / this.itemsPerPage);
    },
    paginatedInstructors() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.instructorlist.slice(start, end);
    },
  },
  created() {
    this.instructorService = new InstructorService();
  },
  mounted() {
    this.obtenerInstructores();
  },
  methods: {
    obtenerInstructores() {
      this.instructorService.getInstructor().then((response) => {
        if (!response.success) {
          return;
        }
        this.instructorlist = response.instructores;
      });
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    editInstructor(id) {
      this.$router.push({ name: "register-step-one", params: { id } });
    },
    activarDesactivar(id) {
      this.instructorService.activarDesactivar(id).then((response) => {
        if (!response.success) {
          this.$message.error(response.message);
        }
        this.obtenerInstructores();
      });
    },
  },
};
</script>
<style>
  .btn-editar:hover {
    background-color: #14123b;
    color: #fff
  }
  .btn-delete:hover {
    background-color: #dc3545;
    color: #fff
  }
  .btn-activar {
    font-size: 20px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border: 1px solid #28a745;
    border-radius: 5px;
    background-color: #fff;
    color: #28a745
  }
  .btn-activar:hover {
    background-color: #28a745;
    color: #fff
  }
</style>