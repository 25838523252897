<template>
  <student-header></student-header>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- barra lateral -->
        <student-sidebar></student-sidebar>
        <!-- /Barra lateral -->

        <!-- Historial de Pedidos del Estudiante -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>
                  <i
                    class="pi pi-shopping-cart"
                    style="font-size: 20px; font-weight: bold"
                  >
                  </i>
                  Historial de Pedidos
                </h3>
              </div>
              <div class="checkout-form">
                <!-- Pestañas de Pedidos -->
                <div class="wishlist-tab order-tab" v-if="false">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        class="active"
                        data-bs-toggle="tab"
                        data-bs-target="#today"
                        >Hoy</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#month"
                        >Mensual</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#year"
                        >Anual</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- /Pestañas de Pedidos -->

                <!-- Contenido de Pestañas -->
                <div class="tab-content">
                  <!-- Hoy -->
                  <today-history></today-history>
                  <!-- /Hoy -->

                  <!-- Mes -->
                  <div class="tab-pane fade" id="month">
                    <month-history></month-history>
                  </div>
                  <!-- /Mes -->

                  <!-- Anual -->
                  <div class="tab-pane fade" id="year">
                    <year-history></year-history>
                  </div>
                  <!-- /Anual -->
                </div>
                <!-- /Contenido de Pestañas -->
              </div>
            </div>
          </div>

          <div class="dash-pagination">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Página 1 de 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Historial de Pedidos del Estudiante -->
      </div>
    </div>
  </div>
  <layouts1></layouts1>
</template>

<script>
export default {
  data() {
    return {
      title: "Historial de Pedidos",
      text: "Inicio",
      text1: "Historial de Pedidos",
    };
  },
};
</script>
