import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class CertificacionService {
  async getCertificacion() {
    return await fetchWrapper.get(`${ruta}/certificaciones`);
  }
  async saveCertificacion(datos) {
    return await fetchWrapper.post(`${ruta}/certificaciones`, datos);
  }
  async updateCertificacion(datos) {
    return await fetchWrapper.put(`${ruta}/certificaciones/` + datos.id, datos);
  }
  async deleteCertificacion(id) {
    return await fetchWrapper.put(`${ruta}/certificaciones/eliminar/${id}`);
  }
}
