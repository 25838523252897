<template>
  <section class="section-opiniones">
    <div class="computer">
      <div style="display: flex; justify-content: center">
        <h2>SECCIÓN DE COMENTARIOS:</h2>
      </div>
      <Carousel
        :items-to-show="itemsToShow"
        snapAlign="center-even"
        :autoplay="3000"
        :wrap-around="true"
        :pauseAutoplayOnHover="true"
      >
        <Slide v-for="(comment, index) in comments" :key="index">
          <div style="padding: 10px; border: 2px solid #171723">
            <div class="cuadro">
              <div class="content-img">
                <h3 class="icon" @click="removeComment(index)">x</h3>
              </div>
              <div style="padding: 5px; max-width: 70vh">
                <p>{{ comment }}</p>
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
    
  </section>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      comments: [
        "Excelente centro de capacitación, con docentes con amplio conocimiento en las soluciones que imparten.",
        "Exelente contenido y de acuerdo a lo ofrecido, bastante didactico e instructores con bastante paciencia.",
        "Excelentes cursos, docentes de alto nivel.",
      ],
      itemsToShow: 2, // Mostrar 2 ítems por defecto
    };
  },
  methods: {
    removeComment(index) {
      this.comments.splice(index, 1); // Elimina el comentario por su índice
    },
    updateItemsToShow() {
      this.itemsToShow = window.innerWidth < 768 ? 1 : 2; // Cambia a 1 en pantallas pequeñas
    },
  },
  mounted() {
    this.updateItemsToShow(); // Establece el valor inicial según el tamaño de la pantalla
    window.addEventListener("resize", this.updateItemsToShow); // Escucha cambios en el tamaño de la ventana
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateItemsToShow); // Limpia el evento al desmontar el componente
  },
};
</script>

<style>
.section-opiniones {
  padding: 120px 0 40px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
.section-opiniones::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 30px 30px;
  background-image: linear-gradient(
      to right,
      rgba(20, 18, 59, 0.147) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(20, 18, 59, 0.147) 1px, transparent 1px);
  animation: moverCuadricula 5s linear infinite;
}
.computer {
  width: 80% !important;
  height: 100%;
  padding: 15px 0px;
}

.cuadro {
  display: grid;
  flex-direction: column;
  border-radius: 5px;
  background-color: #171723;
  filter: drop-shadow(#201d64 0px 0px 10px);
}

.content-img {
  display: flex;
  justify-content: flex-end;
  border-bottom: 2px solid white;
  padding: 5px;
}

.icon {
  color: white;
  cursor: pointer;
  margin-right: 5px;
}

.cuadro p {
  color: white;
}
</style>
