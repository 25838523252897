<template>
    <div class="settings-widget card-details">
      <div class="settings-menu p-0">
        <div class="profile-heading">
          <h3>
            <i class="pi pi-users" style="font-size: 20px; font-weight: bold">
            </i>
            ADMINISTRAR CERTIFICACIONES :
            <button class="btn-new-user" @click="abrirModal()">
              <i
                class="pi pi-plus"
                style="font-size: 20px; font-weight: bold"
              ></i>
            </button>
          </h3>
        </div>
        <div class="checkout-form">
          <!-- Contenido de Pestañas -->
          <div class="tab-content">
            <div class="tab-pane show active">
              <div class="table-responsive custom-table">
                <table class="table table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>NOMBRE</th>
                      <th>DESCRIPCIÓN</th>
                      <th>ESTADO</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="certificacion in paginationCertificaciones"
                      :key="certificacion.id"
                    >
                      <td>{{ certificacion.id }}</td>
                      <td>
                        {{ certificacion.nombre }}
                      </td>
                      <td>{{ certificacion.descripcion }}</td>
                      <td
                        :class="{
                          'text-success': certificacion.estado == 1,
                          'text-danger': certificacion.estado == 0,
                        }"
                      >
                        {{ certificacion.estado == 1 ? "Activo" : "Inactivo" }}
                      </td>
                      <td>
                        <button @click="abrirModal(certificacion)" class="btn-editar">
                          <i class="pi pi-user-edit"></i>
                        </button>
                        <button
                          :class="
                            certificacion.estado === 1 ? 'btn-delete' : 'btn-activar'
                          "
                          @click="eliminarCertificacion(certificacion.id)"
                        >
                          <i
                            :class="
                              certificacion.estado === 1
                                ? 'pi pi-times'
                                : 'pi pi-check'
                            "
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination :totalPages="totalPages" v-model:currentPage="currentPage" />
        </div>
      </div>
      <div
        v-if="mostrarModal"
        class="modal fade show"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalAgregarCerificacion"
        aria-hidden="true"
        style="display: block; background-color: rgba(0, 0, 0, 0.5)"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {{ esEdicion ? "Editar certificacion" : "Agregar certificacion" }}
              </h5>
              <button
                type="button"
                class="close"
                @click="cerrarModal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="guardarCertificacion">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    v-model="certificacion.nombre"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripción</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    v-model="certificacion.descripcion"
                  />
                </div>
                <div class="form-group">
                  <label for="estado">Estado</label>
                  <select
                    class="form-control"
                    id="estado"
                    v-model="certificacion.estado"
                  >
                    <option value="1">Activo</option>
                    <option value="0">Inactivo</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="cerrarModal"
                  >
                    Cerrar
                  </button>
                  <button type="submit" class="btn btn-primary">
                    {{ esEdicion ? "Actualizar" : "Guardar" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import CertificacionService from "@/service/CertificacionService";
  import Pagination from "@/components/lms-pagination.vue";
  
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        certificaciones: [],
        certificacion: {
          id: null,
          nombre: "",
          descripcion: "",
          estado: "1",
        },
        mostrarModal: false,
        esEdicion: false,
        currentPage: 1,
        itemsPerPage: 5,
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.certificaciones.length / this.itemsPerPage);
      },
      paginationCertificaciones() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.certificaciones.slice(start, end);
      },
    },
    created() {
      this.certificacionService = new CertificacionService();
    },
    mounted() {
      this.obtenerCertificaciones();
    },
    methods: {
      abrirModal(certificacion = null) {
        if (certificacion) {
          // Modo edición
          this.esEdicion = true;
          this.certificacion = { ...certificacion }; // Cargar datos de la categoría seleccionada
        } else {
          // Modo agregar
          this.esEdicion = false;
          this.certificacion = {
            id: null,
            nombre: "",
            descripcion: "",
            estado: "1",
          };
        }
        this.mostrarModal = true;
      },
      cerrarModal() {
        this.mostrarModal = false;
      },
      guardarCertificacion() {
        if (this.esEdicion) {
          // Actualizar certificacion
          let data = {
            id: this.certificacion.id,
            nombre: this.certificacion.nombre,
            descripcion: this.certificacion.descripcion,
            estado: this.certificacion.estado,
          };
          this.certificacionService.updateCertificacion(data).then((response) => {
            if (response.success == false) {
              this.$message.error(response.message);
              return;
            } else {
              this.$message.success(response.message);
              this.obtenerCertificaciones();
              this.cerrarModal();
            }
          });
        } else {
          // Guardar nueva categoría
          let data = {
            nombre: this.certificacion.nombre,
            descripcion: this.certificacion.descripcion,
            estado: this.certificacion.estado,
          };
          this.certificacionService.saveCertificacion(data).then((response) => {
            if (response.success == false) {
              this.$message.error(response.message);
              return;
            } else {
              this.$message.success(response.message);
              this.obtenerCertificaciones();
              this.cerrarModal();
            }
          });
        }
      },
      obtenerCertificaciones() {
        this.certificacionService.getCertificacion().then((response) => {
          this.certificaciones = response.certificaciones || [];
        });
      },
      eliminarCertificacion(id) {
        //LANZO UN ALERT PARA CONFIRMAR SI DESEA ELIMINAR
        if (!confirm("¿Está seguro de eliminar la certificacion?")) {
          return;
        }
        this.certificacionService.deleteCertificacion(id).then((response) => {
          if (response.success == false) {
            this.$message.error(response.message);
            return;
          }
          this.$message.success(response.message);
          this.obtenerCertificaciones();
        });
      },
      goToPreviousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToNextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  <style></style>
  