<template>
  <layouts-loginborder></layouts-loginborder>
  <job-category></job-category>

  <!-- Page Wrapper -->
  <div class="page-content">
    <div class="container parametros">
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <Categoria />
        </div>
        <div class="col-xl-6 col-lg-6">
          <Certificasion />
        </div>
        <div class="col-xl-6 col-lg-6">
          <Habilidad />
        </div>
        <div class="col-xl-6 col-lg-6">
          <Nivel />
        </div>
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>

<script>
import Categoria from "./categoria.vue";
import Certificasion from "./certificasion.vue";
import Habilidad from "./habilidad.vue";
import Nivel from "./nivelCurso.vue";

export default {
  components: {
    Categoria,
    Nivel,
    Habilidad,
    Certificasion,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.parametros {
  max-width: 1600px !important;
}
</style>
