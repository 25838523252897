<template>
  <footer class="footer">
    <div class="footer-line"></div>
    <div class="footer-container">
      <div class="footer-section">
        <img
          src="@/assets/img/jaldin_movimiento2.gif"
          alt="Logo"
          class="footer-logo"
        />
        <p class="footer-description">
          "Explora una nueva forma de aprender tecnología y desarrollo. Crece,
          colabora y alcanza tus metas con Papo Network."
        </p>
      </div>

      <div class="footer-section">
        <h3 class="footer-title">Enlaces Rápidos</h3>
        <div class="footer-socials">
          <router-link class="social-link mover-icono" to="/home">
            <i class="fas fa-home"></i> Inicio
          </router-link>
          <router-link class="social-link mover-icono" to="/course/course-grid">
            <i class="fas fa-book"></i> Cursos
          </router-link>
          <router-link
            class="social-link mover-icono"
            to="/acercade/quienessomos"
          >
            <i class="fas fa-users"></i> Quiénes Somos
          </router-link>
          <router-link class="social-link mover-icono" to="/register">
            <i class="fas fa-user-plus"></i> Registrarse
          </router-link>
        </div>
      </div>

      <div class="footer-section">
        <h3 class="footer-title">Síguenos</h3>
        <div class="footer-socials">
          <a
            href="https://www.facebook.com/profile.php?id=61559264905781"
            target="_blank"
            class="social-link mover-icono"
          >
            <i class="fab fa-facebook"></i> Facebook
          </a>
          <a
            href="https://www.instagram.com/paponetwork/"
            target="_blank"
            class="social-link mover-icono"
          >
            <i class="fab fa-instagram"></i> Instagram
          </a>
          <a
            href="https://www.youtube.com/@PapoNetwork/"
            target="_blank"
            class="social-link mover-icono"
          >
            <i class="fab fa-youtube"></i> Youtube
          </a>
          <a
            href="https://www.linkedin.com/company/papo-network/posts/?feedView=all/"
            target="_blank"
            class="social-link mover-icono"
          >
            <i class="fab fa-linkedin"></i> Linkedin
          </a>
        </div>
      </div>

      <div class="footer-section">
        <h3 class="footer-title">Contáctanos</h3>
        <p class="footer-contact">
          <a
            href="mailto:contacto@paponetwork.com"
            class="social-link mover-icono"
          >
            <i class="fas fa-envelope"></i> info@paponetwork.com
          </a>
        </p>
        <p class="footer-contact">
          <a
            href="https://wa.me/59170920222"
            target="_blank"
            class="social-link mover-icono"
          >
            <i class="fab fa-whatsapp whatsapp-icon"></i> +591 70920222
          </a>
        </p>
      </div>
    </div>

    <div class="footer-bottom">
      <span>© 2024 Papo Network. Todos los derechos reservados.</span> |
      <span
        >Desarrollado por
        <a href="https://sitbolivia.com" target="_blank" rel="noopener" style="color: #f7f1ee"
          >SIT BOLIVIA</a
        >.
      </span>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  position: relative;
  background: linear-gradient(135deg, #1d1d2b, #171723);
  color: #f7f1ee;
  padding: 60px 20px; /* Sección más grande */
  overflow: hidden;
}

/* Fondo animado con puntos */
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#ffffff 1px, transparent 1px);
  background-size: 12px 12px;
  animation: move-dots 20s linear infinite;
  z-index: 0;
  opacity: 0.15;
}

.footer-line {
  height: 8px; /* Línea más gruesa */
  background-color: #73cbd6;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(115, 203, 214, 0.7);
}

.footer-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px; /* Más espacio entre las secciones */
}

.footer-section {
  flex: 1;
  min-width: 240px; /* Tamaño mínimo más amplio */
}

.footer-logo {
  width: 220px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-description {
  font-size: 16px;
  line-height: 1.8;
  text-align: center;
  margin-top: 10px;
}

.footer-title {
  /* solo pon borde abajo y entre cortado y mas bonito */
  border-bottom: 2px solid #73cbd6;
  border-radius: 5px;
  padding: 5px;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
  color: #73cbd6;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  text-decoration: none;
  color: #f7f1ee;
  font-size: 16px;
}

.footer-links a:hover {
  color: #73cbd6;
}

.footer-socials {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.social-link {
  color: #f7f1ee;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.social-link i {
  margin-right: 10px;
  font-size: 20px;
  text-shadow: -4px 2px 4px #73cbd6;
}

.social-link:hover {
  color: #8475b6;
}

.footer-contact {
  margin: 10px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.footer-bottom {
  border-top: 2px solid #73cbd6;
  border-radius: 5px;
  text-shadow: -4px 2px 4px #73cbd6;
  text-align: center;
  font-size: 14px;
  margin-top: 30px;
  padding-top: 15px;
}

/* Animación del fondo */
@keyframes move-dots {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 120px 120px;
  }
}
.animated-icon {
  display: inline-block;
  animation: float 3s ease-in-out infinite;
}

/* cuando pase el cursor a los iconos que se mueva a un lado un poquito */
.mover-icono:hover {
  border-bottom: 3px solid #73cbd6;
  border-radius: 5px;
  padding: 5px;
  transition: 0.5s;
  cursor: pointer;
  color: #f7f1ee;
}

/* Animación de flotación */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
