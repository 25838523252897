<template>
  <div class="row">
    <div class="col-12">
      <h3>
        <i class="pi pi-book" style="font-size: 20px; font-weight: bold"> </i>
        <strong> Cursos Inscritos </strong> ({{ totalCursos }})
      </h3>
      <hr />
    </div>
    <div
      class="col-xxl-4 col-md-6 d-flex"
      v-for="curso in cursosInscritos"
      :key="curso.id"
    >
      <div
        class="course-box d-flex aos"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div class="product" style="cursor: pointer; border: 1px solid #171723">
          <div class="product-img">
            <img class="img-fluid" :alt="curso.nombre" :src="curso.imagen" />
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <img
                  :src="curso.foto_instructor"
                  alt="Instructor"
                  class="img-fluid"
                />
                <div class="course-name">
                  <h4>{{ curso.instructor }}</h4>
                  <p>Instructor</p>
                </div>
              </div>
            </div>
            <p>
              {{ curso.nombre }}
            </p>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Lecciones" />
                <p>{{ curso.temas }}+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Duración" />
                <p>{{ curso.duracion }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CursoService from "@/service/CursoService";
import AOS from "aos";
import { useAuth } from "@/stores"; // Importar el store de autenticación

export default {
  data() {
    return {
      cursosInscritos: [],
      totalCursos: 0,
    };
  },
  cursoService: null,
  auth: null,

  created() {
    this.cursoService = new CursoService();
    this.auth = useAuth();
  },

  mounted() {
    this.obtenerCursosInscritos();
    AOS.init();
  },
  methods: {
    obtenerCursosInscritos() {
      const data = {
        estudiante_id: this.auth.user
          ? this.auth.user.instructor_estudiante_id
          : null,
      };
      this.cursoService
        .obtenerCursosInscritos(data)
        .then((response) => {
          this.cursosInscritos = response.cursos;
          this.totalCursos = response.cursos ? response.cursos.length : 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
