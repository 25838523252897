<template>
  <div class="loading-container" v-if="loading">
    <ProgressSpinner
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="transparent"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
  </div>
  <div v-else>
    <page-header v-if="auth.user"></page-header>
    <horizontal-header v-else></horizontal-header>
    <!-- <coursegrid-breadcrumb></coursegrid-breadcrumb> -->
    <!-- Course -->
    <section class="course-content academy-container">
      <div class="container">
        <div class="row">
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
            <h1 class="titulo-h1">
              Nos caracteriza la pasi&oacute;n por la tecnolog&iacute;a y la enseñanza
            </h1>
            <p class="descripcion-texto">
              La plataforma de educación en T.I de Papo Network es real y
              directa, cada tecnología, cada profesión, cada habilidad se basa
              en principios básicos. Estos son los que extraeremos y
              explicaremos para ustedes.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="linea-neon"></div>
        </div>

        <div class="row">
          <div class="col-lg-3">
            <div class="row">
              <div class="filter-container">
                <!-- Campo de búsqueda -->
                <div class="search-box">
                  <input
                    type="text"
                    v-model="nombre_curso"
                    placeholder="Buscar..."
                    class="form-control"
                  />
                </div>

                <!-- Nivel de curso -->
                <div class="filter-group">
                  <h5 class="titulo-h5">
                    <i class="fas fa-layer-group"></i> Nivel de curso
                  </h5>
                  <div
                    v-for="nivel in niveles_cursos"
                    :key="nivel"
                    class="filter-item"
                  >
                    <input
                      type="checkbox"
                      :value="nivel"
                      v-model="selectedFilters.niveles_cursos"
                    />
                    <label>{{ nivel.nombre }}</label>
                  </div>
                </div>

                <!-- Instructor -->
                <div class="filter-group">
                  <h5 class="titulo-h5">
                    <i class="fas fa-user-tie"></i> Instructor
                  </h5>
                  <div
                    v-for="instructor in instructores"
                    :key="instructor"
                    class="filter-item"
                  >
                    <input
                      type="checkbox"
                      :value="instructor"
                      v-model="selectedFilters.instructores"
                    />
                    <label>{{ instructor.nombre_completo }}</label>
                  </div>
                </div>

                <!-- Categorías -->
                <div class="filter-group">
                  <h5 class="titulo-h5">
                    <i class="fas fa-list"></i> Categorías
                  </h5>
                  <div
                    v-for="categoria in categorias"
                    :key="categoria"
                    class="filter-item"
                  >
                    <input
                      type="checkbox"
                      :value="categoria"
                      v-model="selectedFilters.categorias"
                    />
                    <label>{{ categoria.nombre }}</label>
                  </div>
                </div>

                <!-- Certificaciones -->
                <div class="filter-group">
                  <h5 class="titulo-h5">
                    <i class="fas fa-certificate"></i> Certificaciones
                  </h5>
                  <div
                    v-for="certificacion in certificaciones"
                    :key="certificacion"
                    class="filter-item"
                  >
                    <input
                      type="checkbox"
                      :value="certificacion"
                      v-model="selectedFilters.certificaciones"
                    />
                    <label>{{ certificacion.nombre }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <!-- <course-filter></course-filter> -->
            <div class="row" data-aos="fade-up" data-aos-duration="1000">
              <div class="course-feature">
                <div class="row">
                  <!-- Verifica si hay cursos, de lo contrario muestra un mensaje más estilizado -->
                  <div v-if="cursos.length === 0" class="col-12 text-center">
                    <div class="no-courses-message">
                      <i class="fas fa-exclamation-triangle"></i>
                      <p>
                        <strong
                          >Lo sentimos, no encontramos cursos con esos
                          filtros.</strong
                        >
                      </p>
                      <p>
                        Intenta con otros filtros o realiza una búsqueda
                        diferente.
                      </p>
                    </div>
                  </div>

                  <div v-else class="row">
                    <div
                      class="col-lg-6 col-md-6 d-flex"
                      v-for="curso in cursos"
                      :key="curso.id"
                    >
                      <div class="course-box d-flex aos" data-aos="fade-up">
                        <div class="product">
                          <div class="product-img">
                            <router-link
                              :to="`/course/course-details/${curso.id}`"
                            >
                              <img
                                class="img-fluid"
                                :alt="curso.nombre"
                                :src="curso.imagen"
                              />
                            </router-link>
                            <div class="price">
                              <h3>
                                Bs. {{ curso.precio }}
                                <span
                                  >Bs.
                                  {{ (curso.precio * 1.5).toFixed(2) }}</span
                                >
                              </h3>
                            </div>
                          </div>
                          <div class="product-content">
                            <div class="course-group d-flex">
                              <div class="course-group-img d-flex">
                                <img
                                  :src="curso.foto_instructor"
                                  alt=""
                                  class="img-fluid"
                                />
                                <div class="course-name">
                                  <h4>
                                    {{ curso.instructor }}
                                  </h4>
                                  <p>Instructor</p>
                                </div>
                              </div>
                              <div
                                v-if="false"
                                class="course-share d-flex align-items-center justify-content-center"
                              >
                                <a href="javascript:void(0);">
                                  <i class="fa-regular fa-heart"></i>
                                </a>
                              </div>
                            </div>
                            <h3 class="titulo-h3">
                              <router-link
                                :to="`/course/course-details/${curso.id}`"
                              >
                                {{ curso.nombre }}
                              </router-link>
                            </h3>
                            <div class="course-info d-flex align-items-center">
                              <div class="rating-img d-flex align-items-center">
                                <img
                                  src="@/assets/img/icon/icon-01.svg"
                                  alt=""
                                />
                                <p>{{ curso.temas }}+ Lección</p>
                              </div>
                              <div
                                class="course-view d-flex align-items-center"
                              >
                                <img
                                  src="@/assets/img/icon/icon-02.svg"
                                  alt=""
                                />
                                <p>{{ curso.duracion }}</p>
                              </div>
                            </div>
                            <div
                              class="d-flex align-items-center w-100"
                              v-if="auth.user.id === 1 || auth.user.id === 2"
                            >
                              <router-link
                                :to="`/course/add-course/${curso.id}`"
                                class="button button-editar btn-block"
                              >
                                <span> EDITAR</span>
                              </router-link>
                              <button
                                to="/pages/checkout"
                                class="button button-activar-desactivar btn-block"
                                data-action="DESACTIVAR"
                                @click="confirmacion(curso.id)"
                              >
                                <span> DESACTIVAR</span>
                              </button>
                            </div>
                            <div class="d-flex align-items-center w-100">
                              <!-- <router-link
                              to="/pages/checkout"
                              class="button button-comprar btn-block"
                            >
                              <span
                                ><i class="fas fa-shopping-cart"></i>
                                COMPRAR</span
                              >
                            </router-link> -->
                              <router-link
                                :to="`/pages/pago/${curso.id}`"
                                class="button button-comprar btn-block"
                              >
                                <span
                                  ><i class="fas fa-shopping-cart"></i>
                                  COMPRAR</span
                                >
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  <pagination></pagination> -->
          </div>
          <!-- <course-sidebar></course-sidebar> -->
        </div>
      </div>
    </section>
    <!-- /Course -->

    <layouts1></layouts1>
  </div>
</template>
<script>
import CursoService from "@/service/CursoService";
import AOS from "aos";
import { useAuth } from "@/stores"; // Importar el store de autenticación
import { ProgressSpinner } from "primevue/progressspinner";

export default {
  components: {
    ProgressSpinner,
  },
  data() {
    return {
      loading: false,
      cursos: [],
      niveles_cursos: [],
      instructores: [],
      categorias: [],
      certificaciones: [],
      selectedFilters: {
        niveles_cursos: [],
        instructores: [],
        categorias: [],
        certificaciones: [],
      },
      auth: useAuth(),
      nombre_curso: "", // Campo de búsqueda
      isLoading: false, // Indicador de carga para mostrar mientras se aplican filtros
    };
  },

  cursoService: null,

  mounted() {
    this.$nextTick(() => {
      this.obtenerCursos(); // Obtener cursos al cargar la página
      this.datosNecessariosCurso();
      AOS.init(); // Inicialización de animaciones
    });
  },

  created() {
    this.cursoService = new CursoService();
  },

  methods: {
    applyFilters() {
      const activeFilters = {
        ...this.selectedFilters,
        nombre_curso: this.nombre_curso.trim(), // Normaliza espacios
      };

      // Verificar si no hay filtros activos
      const hasActiveFilters = Object.values(activeFilters).some(
        (filter) => filter && filter.length > 0
      );

      if (!hasActiveFilters) {
        this.obtenerCursos(); // Llamar a la función para obtener todos los cursos
        return; // No hacer la solicitud de filtros si no hay filtros activos
      }

      this.isLoading = true; // Activa indicador de carga
      this.cursoService
        .obtenerCursosFiltrados(activeFilters)
        .then((response) => {
          this.cursos = response.cursos;
        })
        .catch((error) => {
          console.error("Error al filtrar cursos:", error);
        })
        .finally(() => {
          this.isLoading = false; // Desactiva indicador de carga
        });
    },

    obtenerCursos() {
      this.isLoading = true; // Activa el indicador de carga
      this.cursoService
        .obtenerCursos() // Llama al servicio para obtener todos los cursos
        .then((response) => {
          this.cursos = response.cursos;
        })
        .catch((error) => {
          console.error("Error al obtener cursos:", error);
        })
        .finally(() => {
          this.isLoading = false; // Desactiva el indicador de carga
        });
    },

    datosNecessariosCurso() {
      this.cursoService
        .datosNecessariosCurso()
        .then((response) => {
          this.niveles_cursos = response.niveles_cursos;
          this.instructores = response.instructores;
          this.categorias = response.categorias;
          this.certificaciones = response.certificaciones;
        })
        .catch((error) => {
          console.error(
            "Error al obtener datos necesarios para filtros:",
            error
          );
        });
    },
    confirmacion(id) {
      const resultado = confirm(
        "¿Estás seguro de que deseas desactivar este curso?"
      );
      if (resultado) {
        this.desactivarCurso(id);
      } else {
        this.$message.info("Operación cancelada");
      }
    },
    desactivarCurso(id) {
      this.loading = true;
      this.cursoService
        .desactivarCurso(id)
        .then((response) => {
          if (response.success) {
            this.obtenerCursos();
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
      this.loading = false;
    },
  },

  watch: {
    // Aplica filtros automáticamente cuando cambian
    selectedFilters: {
      handler: "applyFilters",
      deep: true,
    },
    nombre_curso: "applyFilters",
  },
};
</script>
<style>
.filter-container {
  background: #171723;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #f7f1ee;
}

.search-box {
  margin-bottom: 20px;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  width: 100%;
  height: 20px;
}
/* HAGO EL CHECK MAS GRANDE */
.filter-item input[type="checkbox"] {
  width: 20px;
  cursor: pointer;
  margin-right: 5px;
}

.filter-item label {
  color: #f7f1ee;
  font-family: "OCRAEXT";
  font-size: 14px;
}
/* Botón Base */
.button {
  display: block;
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #171723;
  background-color: #f7f1ee;
  border: 2px solid #171723;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

/* Fondo animado */
.button::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #233269, #171723);
  transition: all 0.4s ease-in-out;
  z-index: 0;
}

/* Hover común */
.button:hover::before {
  top: 0;
}

/* Botón Comprar */
.button-comprar:hover {
  color: #f7f1ee;
  border-color: #171723;
}

/* Botón Editar */
.button-editar:hover {
  color: #14ce8e;
  border-color: #171723;
}

/* Botón Activar/Desactivar dinámico */
.button-activar-desactivar:hover {
  border-color: #171723;
}

/* Cambiar color según texto del botón */
.button-activar-desactivar[data-action="ACTIVAR"]:hover {
  color: #088907;
}

.button-activar-desactivar[data-action="DESACTIVAR"]:hover {
  color: red;
}

.button span {
  position: relative;
  z-index: 1;
}

/* Responsivo */
@media (max-width: 768px) {
  .button {
    font-size: 16px;
    padding: 12px 15px;
  }
}

@media (max-width: 480px) {
  .button {
    font-size: 14px;
    padding: 10px 12px;
  }
}

.no-courses-message {
  padding: 20px;
  background-color: #f7f1ee;
  border-radius: 8px;
  border: 1px solid #f7f1ee;
  color: #721c24;
  font-size: 1.2rem;
}

.no-courses-message i {
  font-size: 2rem;
  margin-bottom: 10px;
}

.no-courses-message p {
  margin: 0;
  text-align: center;
}

.no-courses-message strong {
  display: block;
  margin-bottom: 5px;
}
</style>
