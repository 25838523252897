import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class HabilidadService {
  async getHabilidad() {
    return await fetchWrapper.get(`${ruta}/habilidades`);
  }
  async saveHabilidad(datos) {
    return await fetchWrapper.post(`${ruta}/habilidades`, datos);
  }
  async updateHabilidad(datos) {
    return await fetchWrapper.put(`${ruta}/habilidades/` + datos.id, datos);
  }
  async deleteHabilidad(id) {
    return await fetchWrapper.put(`${ruta}/habilidades/eliminar/${id}`);
  }
}
