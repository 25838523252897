<template>
    <div class="pagination lms-page">
      <li class="page-item prev" @click="goToPreviousPage">
        <a class="page-link cursor"><i class="fas fa-angle-left"></i></a>
      </li>
      <li
        v-for="page in totalPages"
        :key="page"
        class="page-item"
        :class="{ active: page === currentPage }"
        @click="changePage(page)"
      >
        <a class="page-link cursor">{{ page }}</a>
      </li>
      <li class="page-item next" @click="goToNextPage">
        <a class="page-link cursor"><i class="fas fa-angle-right"></i></a>
      </li>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BlogPagination',
    props: {
      totalPages: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
    },
    emits: ['update:currentPage'],
    methods: {
      goToPreviousPage() {
        if (this.currentPage > 1) {
          this.$emit('update:currentPage', this.currentPage - 1);
        }
      },
      goToNextPage() {
        if (this.currentPage < this.totalPages) {
          this.$emit('update:currentPage', this.currentPage + 1);
        }
      },
      changePage(page) {
        if (page !== this.currentPage) {
          this.$emit('update:currentPage', page);
        }
      },
    },
  };
  </script>
<style>
.cursor{
  cursor: pointer;
}
.lms-page {
  justify-content: end;
}
</style>