<template>
  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <!-- Copyright -->
      <div class="copyright">
        <div class="row">
          <div class="col-md-6">
            <div class="privacy-policy">
              <ul>
               <!--  <li><router-link to="/pages/term-condition">Terminos</router-link></li>
                <li><router-link to="/pages/privacy-policy">Privacidad</router-link></li> -->
                <li>Términos y Condiciones</li>
                <li>Política de Privacidad</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="copyright-text">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} Papo Network. Todos los derechos reservados.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /Copyright -->
    </div>
  </div>
  <!-- /Footer Bottom -->
</template>
