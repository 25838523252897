<template>
  <!-- Home Banner -->
  <section class="page-content fondoSettings">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="home-slide-face aos" data-aos="fade-up">
            <div class="home-slide-text">
              <h5>Capacitacion para personas y empresas</h5>
              <h1
                style="color: #f7f1ee; word-spacing: 0; letter-spacing: normal; font-size: 3.8rem; font-weight: 700;"
              >
                Cursos en línea para todos
              </h1>
              <p>
                <span class="texto-aprende"> Aprende: </span>
                <span class="texto-dinamico"> {{ palabraActual }}</span>
              </p>
              <div class="banner-buttons">
                <button class="btn btn-cuadrado" @click="comenzarAprender">
                  Comenzar a aprender
                </button>
                <button class="btn btn-redondeado" @click="queEsPapoNetwork">
                  ¿Que es Papo Network?
                </button>
              </div>
            </div>
            <div class="banner-content" v-if="false">
              <form class="form" @submit.prevent="submitForm">
                <div class="form-inner">
                  <div class="input-group">
                    <i class="fa-solid fa-magnifying-glass search-icon"></i>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Buscar cursos..."
                    />
                    <span class="drop-detail">
                      <vue-select
                        :options="Category"
                        placeholder="Categoría"
                        class="vueselect"
                      />
                    </span>
                    <button class="btn btn-primary sub-btn" type="submit">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="trust-user" v-if="false">
              <p>
                De confianza por más de 15K usuarios<br />en todo el mundo desde
                2023
              </p>
              <div class="trust-rating d-flex align-items-center">
                <div class="rate-head">
                  <h2>
                    <span
                      ><vue3-autocounter
                        class="counter"
                        ref="counter"
                        :startAmount="200"
                        :delay="3"
                        :endAmount="1000"
                        :duration="5"
                        :autoinit="true"
                    /></span>
                  </h2>
                </div>
                <div class="rating d-flex align-items-center">
                  <h2 class="d-inline-block average-rating">4.4</h2>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 d-flex align-items-center">
          <video
            id="video-player"
            class="video-js vjs-default-skin"
            controls
            preload="auto"
            width="640"
            height="360"
          ></video>
        </div>
      </div>
    </div>
  </section>
  <section class="section student-course" v-if="false">
    <div class="container">
      <div class="course-widget">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="course-full-width">
              <div
                class="blur-border course-radius align-items-center aos"
                data-aos="fade-up"
              >
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/pencil-icon.svg" alt="" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="1"
                          :delay="3"
                          :endAmount="10"
                          :duration="5"
                          :autoinit="true" /></span
                      >K
                    </h4>
                    <p>Cursos en</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="course-full-width">
              <div class="blur-border course-radius aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/cources-icon.svg" alt="" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="10"
                          :delay="3"
                          :endAmount="200"
                          :duration="5"
                          :autoinit="true" /></span
                      >+
                    </h4>
                    <p>Tutores expertos</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="course-full-width">
              <div class="blur-border course-radius aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/certificate-icon.svg" alt="" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="1"
                          :delay="3"
                          :endAmount="6"
                          :duration="5"
                          :autoinit="true" /></span
                      >K+
                    </h4>
                    <p>Cursos Certificados</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="course-full-width">
              <div class="blur-border course-radius aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/gratuate-icon.svg" alt="" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="5"
                          :delay="3"
                          :endAmount="60"
                          :duration="5"
                          :autoinit="true" /></span
                      >K +
                    </h4>
                    <p>Estudiantes en línea</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </section>
  <!-- Home Banner -->

  <!-- Top Categories -->
  <section class="section how-it-works" v-if="false">
    <div class="container">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <span>Curso favorito </span>
          <h2>Categoría superior</h2>
        </div>
        <div class="all-btn all-category d-flex align-items-center">
          <router-link to="/pages/job-category" class="btn btn-primary"
            >Todas las categorías</router-link
          >
        </div>
      </div>
      <div class="section-text aos" data-aos="fade-up">
        <p>
          El cliente es muy importante, el cliente será seguido por el cliente.
        </p>
      </div>
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide
          v-for="item in dashboard"
          :key="item.id"
          class="owl-carousel mentoring-course owl-theme"
        >
          <div class="carousel__item feature-box text-center">
            <div class="feature-bg">
              <div class="feature-header">
                <div class="feature-icon">
                  <img :src="require(`@/assets/img/${item.img}`)" alt="" />
                </div>
                <div class="feature-cont">
                  <div class="feature-text">{{ item.Development }}</div>
                </div>
              </div>
              <p>{{ item.Instructors }}</p>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
  <!-- /Top Categories -->

  <!-- PARA COMUNIDAD -->
  <section class="section how-it-works">
    <div class="container">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <span>Conoce a la </span>
          <h2>COMUNIDAD</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head"></div>
          </div>
          <div class="aos" data-aos="fade-up">
            <p>
              Rodéate de una comunidad que comparte tus mismos intereses dentro
              del campus, diseñado para ayudarte a crecer. Pregunta, comparte,
              ayuda y aprende junto al resto de estudiantes.
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 d-flex align-items-end">
          <div class="career-img" data-aos="zoom-in">
            <!-- Animación de entrada -->
            <img
              src="@/assets/img/join.png"
              alt="Imagen de comunidad"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Top Categories -->

  <section class="section master-skill" v-if="false">
    <div class="dots-background"></div>
    <!-- Fondo animado -->
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head">
              <h2><i class="fas fa-graduation-cap"></i> Comunidad</h2>
            </div>
          </div>
          <div class="aos" data-aos="fade-up">
            <p>
              Rodéate de una comunidad que comparte tus mismos intereses dentro
              del campus, diseñado para ayudarte a crecer. Pregunta, comparte,
              ayuda y aprende junto al resto de estudiantes.
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 d-flex align-items-end">
          <div class="career-img" data-aos="zoom-in">
            <!-- Animación de entrada -->
            <img
              src="@/assets/img/join.png"
              alt="Imagen de comunidad"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import dashboard from "@/assets/json/dashboard.json";
import "vue3-carousel/dist/carousel.css";
import AOS from "aos";
import "aos/dist/aos.css";
// Importo Categoria
import CategoriaService from "@/service/CategoriaService";
import videojs from "video.js"; // Importa la librería de Video.js
import "videojs-youtube";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      Category: [],
      dashboard: dashboard,
      player: null, // Referencia al reproductor de video
      palabraActual: "",
      palabras: [
        "Ciberseguridad",
        "Fortinet",
        "Cisco",
        "Servidores",
        "desarrollo",
        "Redes",
      ],
      indicePalabra: 0,
      indiceLetra: 0,
    };
  },
  categoriaService: null,
  created() {
    this.categoriaService = new CategoriaService();
  },
  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    iniciarRotacionLetras() {
      setInterval(() => {
        const palabra = this.palabras[this.indicePalabra]; // Obtiene la palabra actual
        if (palabra && this.indiceLetra < palabra.length) {
          // Si no hemos terminado la palabra
          this.palabraActual += palabra[this.indiceLetra]; // Añade la siguiente letra
          this.indiceLetra++; // Avanza al siguiente carácter
        } else {
          // Si terminamos la palabra, pasa a la siguiente
          this.indiceLetra = 0; // Reinicia el índice de letras
          this.indicePalabra = (this.indicePalabra + 1) % this.palabras.length; // Avanza al siguiente índice de palabra (cíclico)
          this.palabraActual = ""; // Limpia el texto para empezar con la nueva palabra
        }
      }, 350); // Intervalo entre cada letra
    },

    submitForm() {
      this.$router.push("/course-list");
    },
    obtenerCategorias() {
      this.categoriaService.obtenerCategorias().then((response) => {
        this.Category = response.categorias || [];
        this.palabras = this.Category.map((categoria) => categoria.nombre); // Llena el arreglo 'palabras' con los nombres de las categorías
        for (let i = 0; i < this.Category.length; i++) {
          this.Category[i].value = this.Category[i].id;
          this.Category[i].text = this.Category[i].nombre;
        }
      });
    },
    cargarVideo() {
      // Inicializa el reproductor de Video.js
      this.player = videojs("video-player", {
        techOrder: ["youtube"], // Especifica que se usará YouTube
        sources: [
          {
            type: "video/youtube",
            src: "https://youtu.be/PrKtamP-cqc?si=kqWtS4FJb-KuuYpP", // URL del video
          },
        ],
        controls: true,
        preload: "auto",
        //hago que se reproduzca automáticamente
        autoplay: true,
        //que sea mute
        muted: true,

        // Opciones de apariencia
        controlBar: {
          volumePanel: {
            inline: false,
          },
        },
      });
    },
    comenzarAprender() {
      const section = document.querySelector(".cursos_portada");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        alert("No se encontró la sección de cursos");
      }
    },
    queEsPapoNetwork() {
      const section = document.querySelector(".PapoNetwork");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        alert("No se encontró la sección de ¿Qué es Papo Network?");
      }
    },
  },
  mounted() {
    this.obtenerCategorias();
    this.cargarVideo(); // Llama a la función para cargar el video
    this.$nextTick(() => {
      AOS.init();
      this.iniciarRotacionLetras();
    });
  },
  beforeUnmount() {
    // Destruye el reproductor para evitar fugas de memoria
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style scoped>
@import "https://vjs.zencdn.net/8.0.4/video-js.css";

.texto-dinamico {
  background-color: #73cad689;
  color: #f7f1ee;
  border: 1px solid #233269;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  font-weight: bold;
  font-size: 1.2em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid #233269;
}
.texto-aprende {
  font-size: 1.2em;
  color: #f7f1ee;
}

/* Fondo de cuadrículas o puntos animados */
.dots-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Detrás del contenido */
  background: radial-gradient(circle, #73cbd6 1px, transparent 1px),
    radial-gradient(circle, #73cbd6 1px, transparent 1px);
  background-size: 20px 20px; /* Tamaño de las cuadrículas o puntos */
  background-position: 0 0, 10px 10px; /* Alterna las filas */
  animation: moveDots 10s linear infinite;
  opacity: 0.3; /* Efecto sutil */
}

@keyframes moveDots {
  0% {
    background-position: 0 0, 10px 10px;
  }
  100% {
    background-position: -10px -10px, 0 0;
  }
}

/* Sección principal */
.section.master-skill {
  position: relative;
  padding: 150px 0;
  background-color: #f7f1ee;
  overflow: hidden;
  color: #233269;
}

/* Encabezado */
.section-header h2 {
  font-size: 2.8rem;
  color: #171723;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative; /* Por encima del fondo */
  z-index: 1;
}

.section-header h2 i {
  color: #73cbd6;
  font-size: 2.4rem;
}

/* Texto */
.aos {
  margin-bottom: 30px;
  text-align: justify;
  position: relative;
  z-index: 1;
}

.aos p {
  font-size: 1.2rem;
  color: #233269;
  line-height: 1.8;
}

/* Imagen */
Aprende .career-img {
  position: relative;
  overflow: hidden; /* Oculta contenido extra */
  z-index: 1;
}

.career-img img {
  width: 100%;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0; /* Inicialmente invisible */
  transform: translateY(20px); /* Posición inicial para animación */
}

.career-img[data-aos="zoom-in"] img {
  animation: fadeInUp 1s forwards; /* Aparece al cargar */
}

@keyframes fadeInUp {
  to {
    opacity: 1; /* Visible */
    transform: translateY(0); /* Posición final */
  }
}

/* Adaptabilidad */
@media (max-width: 768px) {
  .section-header h2 {
    font-size: 2.2rem;
  }
}
</style>
