<template>
  <ul class="main-nav nav header-navbar-rht">
    <main-nav></main-nav>
    <li class="nav-item user-nav" v-if="false">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>
    <li class="nav-item user-nav">
      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
        <span class="user-img">
          <img :src="src" alt="Img" />
          <span class="status online"></span>
        </span>
      </a>
      <div
        class="users dropdown-menu dropdown-menu-right"
        data-popper-placement="bottom-end"
      >
        <div class="user-header">
          <div class="avatar avatar-sm">
            <img
              :src="src"
              alt="User Image"
              class="avatar-img rounded-circle"
            />
          </div>
          <div class="user-text">
            <h6 v-if="instructor">{{ instructor.nombres }} {{ instructor.apellidos }}</h6>
            <h6 v-else>{{ user ? user.name : "" }}</h6>
            <!-- <p v-if="instructor" class="text-muted mb-0">Instructor</p>
            <p v-else class="text-muted mb-0">Administrador</p> -->
          </div>
        </div>
        <router-link
          class="dropdown-item"
          to="/instructor/instructor-dashboard"
          v-if="instructor"
          ><i class="feather-home me-1"></i> Panel de control</router-link
        >
        <router-link
          class="dropdown-item"
          to="/instructor/instructor-settings"
          v-if="instructor"
          ><i class="feather-star me-1"></i> Editar Perfil</router-link
        >
        <div class="dropdown-item night-mode" v-if="false">
          <span><i class="feather-moon me-1"></i> Modo Oscuro </span>
          <div class="form-check form-switch check-on m-0">
            <input class="form-check-input" type="checkbox" id="night-mode" />
          </div>
        </div>
        <button class="dropdown-item" @click="cerrarSesion">
          <i class="feather-log-out me-1"></i> Cerrar sesión
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
import UserService from "@/service/UserService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      darkModeToggle: null,
      lightModeToggle: null,
      instructor: null,
      user: null,
      src: require("@/assets/img/user/avatar.png"),
    };
  },
  created() {
    this.auth = useAuth();
    this.userService = new UserService();
  },
  mounted() {
    // Asigna referencias a los elementos del DOM
    this.darkModeToggle = this.$refs.darkModeToggle;
    this.lightModeToggle = this.$refs.lightModeToggle;

    // Verifica el modo actual en la carga de la página
    const darkMode = localStorage.getItem("darkMode");
    if (darkMode === "enabled") {
      this.enableDarkMode();
    } else {
      this.disableDarkMode();
    }
    this.obtenerUsuario();
  },
  methods: {
    obtenerUsuario() {
      this.userService.getUser().then((response) => {
        this.user = response.user;
        if (!response.success) {
          this.src = require("@/assets/img/logo/avatar_papo.png");
          return;
        }
        if (this.user.tipo_usuario === 4) {
          return;
        }
        this.instructor = response.usuario;

        this.src = response.usuario.foto
          ? response.usuario.foto
          : require("@/assets/img/user/avatar.png");
      });
    },
    // Función para habilitar el modo oscuro
    enableDarkMode() {
      document.documentElement.setAttribute("class", "light dark");
      this.darkModeToggle.classList.remove("activate");
      this.lightModeToggle.classList.add("activate");
      localStorage.setItem("darkMode", "enabled");
    },

    // Función para deshabilitar el modo oscuro
    disableDarkMode() {
      /* document.documentElement.setAttribute("class", "light");
      this.lightModeToggle.classList.remove("activate");
      this.darkModeToggle.classList.add("activate");
      localStorage.removeItem("darkMode"); */
    },
    cerrarSesion() {
      if (confirm("¿Está seguro de cerrar sesión?")) this.auth.userLogout();
    },
  },
};
</script>
