<template>
  <!-- Feature Course -->
  <section class="cursos_portada" id="cursos_portada" style="overflow-y: auto">
    <div class="container">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <span style="color: #f7f1ee" class="texto_cursos"
            >Qué hay de nuevo
          </span>
          <h2 style="color: #f7f1ee">Cursos destacados</h2>
        </div>
        <div class="all-btn all-category d-flex align-items-center">
          <router-link
            to="/course/course-grid"
            class="btn btn-primary"
            style="color: #f7f1ee"
            ><i class="fas fa-th-large"> </i> Todos los cursos</router-link
          >
        </div>
      </div>
      <div class="course-feature">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 d-flex"
            v-for="curso in cursos"
            :key="curso.id"
          >
            <div class="course-box d-flex aos" data-aos="fade-up">
              <div class="product">
                <div class="product-img">
                  <router-link :to="`/course/course-details/${curso.id}`">
                    <img
                      class="img-fluid"
                      :alt="curso.nombre"
                      :src="curso.imagen"
                    />
                  </router-link>
                  <div class="price">
                    <h3>
                      Bs. {{ curso.precio }}
                      <span>Bs. {{ (curso.precio * 1.5).toFixed(2) }}</span>
                    </h3>
                  </div>
                </div>
                <div class="product-content">
                  <div class="course-group d-flex">
                    <div class="course-group-img d-flex">
                      <img
                        :src="curso.foto_instructor"
                        alt=""
                        class="img-fluid"
                      />
                      <div class="course-name">
                        <h4>
                          {{ curso.instructor }}
                        </h4>
                        <p>Instructor</p>
                      </div>
                    </div>
                    <div
                      v-if="false"
                      class="course-share d-flex align-items-center justify-content-center"
                    >
                      <a href="javascript:void(0);">
                        <i class="fa-regular fa-heart"></i>
                      </a>
                    </div>
                  </div>
                  <h3 class="titulo-h3">
                    <router-link :to="`/course/course-details/${curso.id}`">
                      {{ curso.nombre }}
                    </router-link>
                  </h3>
                  <div class="course-info d-flex align-items-center">
                    <div class="rating-img d-flex align-items-center p-texto">
                      <img src="@/assets/img/icon/icon-01.svg" alt="" />
                      <p>{{ curso.temas }}+ Lección</p>
                    </div>
                    <div class="course-view d-flex align-items-center p-texto">
                      <img src="@/assets/img/icon/icon-02.svg" alt="" />
                      <p>{{ curso.duracion }}</p>
                    </div>
                  </div>
                  <!--  <div class="btn-comprar d-flex align-items-center">
                          <router-link
                            to="/pages/checkout"
                            class="btn btn-primary btn-comprar"
                          >
                            COMPRAR
                          </router-link>
                        </div> -->
                  <div class="d-flex align-items-center w-100">
                    <!-- <router-link to="/pages/checkout" class="btn btn-cuadrado">
                      <span><i class="fas fa-shopping-cart"></i> COMPRAR</span>
                    </router-link> -->
                    <router-link
                      :to="`/pages/pago/${curso.id}`"
                      class="button button-comprar btn-block"
                    >
                      <span><i class="fas fa-shopping-cart"></i> COMPRAR</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Course -->

  <!-- Master Skill -->
  <section class="section master-skill" v-if="false">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head">
              <span>Qué hay de nuevo</span>
              <h2>Domina las habilidades para impulsar tu carrera</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              Obtenga una certificación, domine las habilidades tecnológicas
              modernas y mejore su carrera, ya sea estás empezando o eres un
              profesional experimentado. El 95% de los estudiantes de eLearning
              reportan nuestra El contenido práctico ayudó directamente a sus
              carreras.
            </p>
          </div>
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-1.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>Manténgase motivado con instructores atractivos</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-2.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>Manténgase al día con lo último en la nube</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-3.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>
                      Obtenga la certificación con más de 100 cursos de
                      certificación
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-4.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>
                      Desarrolle habilidades a su manera, desde laboratorios
                      hasta cursos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 d-flex align-items-end">
          <div class="career-img aos" data-aos="fade-up">
            <img src="@/assets/img/join.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Master Skill -->
  <section class="PapoNetwork" id="PapoNetwork">
    <!-- Primera mitad -->
    <div class="half-section white-bg">
      <div class="content">
        <h2 class="title">¿Qué es Papo Network?</h2>
        <p>
          Donde unimos la teoría con la vida real, brindamos enseñanza plena y
          verdadera, somos transparentes, ¡somos sinceros y leales con tu
          aprendizaje!
        </p>
        <p>
          <strong
            >Traemos a los mejores instructores para ayudarte a mejorar.</strong
          >
        </p>
        <ul class="benefits-list">
          <li><span class="icon">📹</span> Cursos grabados</li>
          <li>
            <span class="icon">📘</span> Videos, test y material adicional
          </li>
          <li>
            <span class="icon">🤝</span> Comunidad privada de estudiantes,
            Discord y soporte
          </li>
          <li>
            <span class="icon">🎥</span> Reunión en vivo con tu mentor al final
            de cada curso
          </li>
          <li><span class="icon">📜</span> Certificado de finalización</li>
        </ul>

        <div class="note">
          <p>
            <strong>
              Un certificado no define tu capacidad; lo importante es lo que
              aprendes y cómo lo aplicas en la práctica.
            </strong>
          </p>
        </div>
        <router-link to="/acercade/quienessomos" class="btn btn-cuadrado">
          Quiero saber más...
        </router-link>
      </div>
    </div>

    <!-- Segunda mitad -->
    <div class="half-section black-bg">
      <div class="content">
        <!-- <img src="@/assets/img/error-papo.png" alt="Logo" class="footer-logo" /> -->
        <h2 class="title">
          <br /><br />
          ¿Quién es Papo Network?
        </h2>
        <p class="texto-blanco">
          Papo Network es la visión de
          <strong>Rubén Jaldin Salvatierra</strong>, un profesional apasionado
          con una sólida trayectoria en Tecnología y Ciberseguridad. Desde la
          Banca, Salud, Auditoría y Retail, su carrera se ha enfocado en
          desarrollar estrategias donde la tecnológicas y seguridad se
          convierten en catalizadores para el negocio.
        </p>
        <p class="texto-blanco">
          Con más de 15 años de experiencia en administración de personas,
          procesos y tecnológias, Papo Network nace con el propósito
          de democratizar el conocimiento y formar una nueva generación de
          expertos en T.I. y Ciberseguridad en Bolivia y Latinoamérica.
        </p>
        <p class="texto-blanco">
          Papo Network combina la experiencia práctica y la pasión por enseñar
          para ofrecer una educación accesible, integral y aplicable a la vida
          real. Porque aquí, más allá de los certificados, lo importante es lo
          que aprendes y cómo lo demuestras.
        </p>
        <p class="texto-blanco">
          <strong
            >"Papo Network no es solo una plataforma, es un movimiento que
            transforma la educación en T.I. en una experiencia significativa y
            con propósito."</strong
          >
          <br />
          <button class="btn btn-redondeado">
            Acceder al contenido gratuito
          </button>
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import CursoService from "@/service/CursoService";
export default {
  data() {
    return {
      cursos: [],
    };
  },

  cursoService: null,

  mounted() {
    this.$nextTick(() => {
      this.obtenerCursos();
      document
        .getElementById("cursos_portada")
        .addEventListener("scroll", () => {
          AOS.refresh(); // Actualiza la detección de animaciones
        });
    });
  },

  created() {
    this.cursoService = new CursoService();
  },

  methods: {
    obtenerCursos() {
      this.cursoService
        .obtenerCursos()
        .then((response) => {
          this.cursos = response.cursos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.btn-comprar {
  display: block; /* Ancho completo */
  width: 100%; /* Responsivo: se adapta al contenedor */
  text-align: center; /* Centra el texto */
  padding: 15px 20px; /* Espaciado interno */
  font-size: 18px; /* Texto ligeramente más grande */
  font-weight: bold;
  text-transform: uppercase; /* Texto en mayúsculas */
  color: #171723; /* Texto blanco */
  background-color: #f7f1ee; /* Fondo inicial */
  border: 2px solid #171723; /* Borde */
  border-radius: 0; /* Bordes cuadrados */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative; /* Para controlar el pseudo-elemento */
  overflow: hidden; /* Contener animaciones */
  z-index: 1; /* Prioridad para el texto */
}

/* Efecto de fondo animado */
.btn-comprar::before {
  content: "";
  position: absolute;
  top: -100%; /* Empieza fuera del botón */
  left: 0;
  width: 100%; /* Ancho completo */
  height: 100%;
  background: linear-gradient(90deg, #233269, #171723); /* Degradado */
  z-index: 0; /* Detrás del texto */
  transition: all 0.4s ease-in-out;
}

/* Animación al pasar el cursor */
.btn-comprar:hover::before {
  top: 0; /* Fondo visible */
}

/* Cambios en el texto y borde al pasar el cursor */
.btn-comprar:hover {
  color: #f7f1ee;
  border-color: #171723;
}

/* Efecto de clic */
.btn-comprar:active {
  transform: scale(0.98);
}

/* El texto encima del pseudo-elemento */
.btn-comprar span {
  position: relative;
  z-index: 1;
}

/* Responsivo para tamaños pequeños */
@media (max-width: 768px) {
  .btn-comprar {
    font-size: 16px; /* Texto más pequeño */
    padding: 12px 15px; /* Ajusta el espaciado */
  }
}

@media (max-width: 480px) {
  .btn-comprar {
    font-size: 14px; /* Texto más compacto */
    padding: 10px 12px;
  }
}

/* SECCION DE QUE ES PAPO */
.PapoNetwork {
  display: flex;
  flex-direction: row;
  padding: 60px 0px 0px 0px;
  font-family: "Poppins-Light";
}

/* Mitades */
.half-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -60px; /* Desplaza ambas mitades hacia arriba */
}

.black-bg {
  background: #171723;
}
.white-bg {
  background: #f7f1ee;
}
.black-bg .title {
  color: #f7f1ee;
}
.texto-blanco {
  color: #f7f1ee;
  font-size: 1.1rem;
  text-align: justify;
  line-height: 1.6;
}
.benefits-list {
  font-size: 18px;
  font-weight: bold;
}
.benefits-list .icon {
  margin-right: 5px;
}

/* Cuadrículas animadas */
.half-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 30px 30px;
  background-image: linear-gradient(
      to right,
      rgba(20, 18, 59, 0.147) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(20, 18, 59, 0.147) 1px, transparent 1px);
  animation: moverCuadricula 5s linear infinite;
}

/* Animación */
@keyframes grid-animation {
  0% {
    transform: translate(-50px, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Contenido */
.content {
  position: relative;
  z-index: 1;
  max-width: 600px;
  color: inherit;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Poppins-Bold";
}

p {
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 20px;
  text-align: justify;
}

.benefits-list {
  list-style-type: square;
  margin-left: 20px;
}

.note {
  font-size: 1rem;
  margin-top: 20px;
  text-align: justify;
}

.note i {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .PapoNetwork {
    flex-direction: column;
    height: auto;
  }

  .half-section {
    width: 100%;
    padding: 20px;
  }
}

.product:hover .product-content h4 {
  color: #f7f1ee;
}
</style>
