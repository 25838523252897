<template>
  <div class="tab-pane show active" id="today">
    <div class="table-responsive custom-table">
      <table
        class="table table-nowrap mb-0"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <thead>
          <tr>
            <th>Pedido Nº</th>
            <th>Nombre del Curso</th>
            <th>Fecha</th>
            <th>Precio</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="curso in cursosInscritos" :key="curso.id">
            <td>{{ curso.inscripcion_id }}</td>
            <td>
              <span class="title-course">{{ curso.nombre }}</span>
            </td>
            <td>{{ curso.fecha_inscripcion }}</td>
            <td>Bs. {{ curso.precio }}</td>
            <td>
              <span v-if="curso.estado_pago === 2" class="text-success"
                >Pagado</span
              >
              <span v-else class="text-danger">Pendiente</span>
            </td>
            <td>
              <a href="javascript:void(0);" class="action-icon">
                <i class="bx bxs-download"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!cursosInscritos.length" class="text-center py-4">
        <p class="text-muted">No tienes cursos inscritos.</p>
      </div>
    </div>
  </div>
</template>

<script>
import CursoService from "@/service/CursoService";
import AOS from "aos";
import { useAuth } from "@/stores"; // Importar el store de autenticación

export default {
  data() {
    return {
      cursosInscritos: [],
    };
  },
  cursoService: null,
  auth: null,

  created() {
    this.cursoService = new CursoService();
    this.auth = useAuth();
  },

  mounted() {
    this.obtenerCursosInscritos();
    AOS.init();
  },
  methods: {
    obtenerCursosInscritos() {
      const data = {
        estudiante_id: this.auth.user
          ? this.auth.user.instructor_estudiante_id
          : null,
      };
      this.cursoService
        .obtenerCursosInscritos(data)
        .then((response) => {
          this.cursosInscritos = response.cursos;
        })
        .catch((error) => {
          console.error("Error al obtener los cursos inscritos:", error);
        });
    },
  },
};
</script>

<style>
.title-course {
  display: block;
  font-weight: 900;
}
.text-success {
  color: #28a745;
  font-weight: 900;
}
.text-danger {
  color: #dc3545;
  font-weight: 900;
}

.custom-table table {
  font-size: 15px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #14123b;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
</style>
