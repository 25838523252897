import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class CursoService {
  async obtenerCursos() {
    return await fetchWrapper.get(`${ruta}/cursos`);
  }

  async guardarCursoNuevo(curso) {
    return await fetchWrapper.post(`${ruta}/cursos/store`, curso);
  }

  async editarCurso(curso) {
    return await fetchWrapper.put(`${ruta}/cursos/update/` + curso.id, curso);
  }

  async detalleCurso(id) {
    return await fetchWrapper.get(`${ruta}/cursos/${id}`);
  }
  async getInstructorAddCourse() {
    return await fetchWrapper.get(`${ruta}/cursos/get/instructors`);
  }
  async getCursoPorInstructor() {
    return await fetchWrapper.get(`${ruta}/instructor/get/cursos`);
  }

  async datosNecessariosCurso() {
    return await fetchWrapper.post(`${ruta}/cursos/datosNecessarios`);
  }

  async obtenerCursosFiltrados(filtro) {
    return await fetchWrapper.post(`${ruta}/cursos/filtrar`, filtro);
  }

  //Para el registro del curso por el estudiante
  async realizarPago(data) {
    return await fetchWrapper.post(`${ruta}/cursos/pagar`, data);
  }

  async desactivarCurso(id) {
    return await fetchWrapper.put(`${ruta}/cursos/desactivar/${id}`);
  }
  
  async obtenerCursosInscritos(datos) {
    return await fetchWrapper.post(`${ruta}/cursos/inscritos`, datos);
  }

}
