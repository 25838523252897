<template>
  <student-header></student-header>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <student-sidebar></student-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Settings -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>
                  <i
                    class="pi pi-key"
                    style="font-size: 20px; font-weight: bold"
                  >
                  </i>
                  Cambiar Contraseña
                </h3>
              </div>
              <form action="/student/student-change-password">
                <div class="checkout-form settings-wrap">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block">
                        <strong>Contraseña Actual:</strong>
                        <InputGroup>
                          <InputText
                            v-model="password"
                            :type="passwordVisible ? 'text' : 'password'"
                            class="form-control"
                          />
                          <InputGroupAddon>
                            <i
                              :class="
                                passwordVisible
                                  ? 'pi pi-eye'
                                  : 'pi pi-eye-slash'
                              "
                              @click="PasswordVisibility"
                            ></i>
                          </InputGroupAddon>
                        </InputGroup>
                        <Message
                          v-if="error.message"
                          severity="error"
                          variant="simple"
                          size="small"
                          >{{ error.message }}</Message
                        >
                      </div>
                      <div class="input-block">
                        <strong>Nueva Contraseña:</strong>
                        <InputGroup>
                          <InputText
                            v-model="passwordNew"
                            :type="passwordNewVisible ? 'text' : 'password'"
                            class="form-control"
                          />
                          <InputGroupAddon>
                            <i
                              :class="
                                passwordNewVisible
                                  ? 'pi pi-eye'
                                  : 'pi pi-eye-slash'
                              "
                              @click="PasswordNewVisibility"
                            ></i>
                          </InputGroupAddon>
                        </InputGroup>
                        <Message
                          v-if="error.new_password"
                          severity="error"
                          variant="simple"
                          size="small"
                          >{{ error.new_password[0] }}</Message
                        >
                      </div>
                      <div class="input-block">
                        <strong>Confirmar Contraseña:</strong>
                        <InputGroup>
                          <InputText
                            v-model="passwordConfirm"
                            :type="passwordConfirmVisible ? 'text' : 'password'"
                            class="form-control"
                          />
                          <InputGroupAddon>
                            <i
                              :class="
                                passwordConfirmVisible
                                  ? 'pi pi-eye'
                                  : 'pi pi-eye-slash'
                              "
                              @click="PasswordConfirmVisibility"
                            ></i>
                          </InputGroupAddon>
                        </InputGroup>
                        <Message
                          v-if="error.confirPassword"
                          severity="error"
                          variant="simple"
                          size="small"
                          >{{ error.confirPassword }}</Message
                        >
                      </div>
                      <div class="input-block">
                        <Button
                          class="btn btn-primary"
                          label="Cambiar Contraseña"
                          @click="updateContrasenia"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Configuración del Estudiante -->
      </div>
    </div>
  </div>
</template>
<script>
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Button from "primevue/button";

import EstudianteService from "@/service/EstudianteService";

export default {
  components: {
    Message,
    InputText,
    InputGroup,
    InputGroupAddon,
    Button,
  },
  data() {
    return {
      title: "Ajustes",
      text: "Inicio",
      text1: "Cambiar Contraseña",
      password: "",
      passwordNew: "",
      passwordConfirm: "",
      error: {},
      passwordVisible: false,
      passwordNewVisible: false,
      passwordConfirmVisible: false,
    };
  },
  created() {
    this.estudianteService = new EstudianteService();
  },
  methods: {
    PasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    PasswordNewVisibility() {
      this.passwordNewVisible = !this.passwordNewVisible;
    },
    PasswordConfirmVisibility() {
      this.passwordConfirmVisible = !this.passwordConfirmVisible;
    },
    updateContrasenia() {
      if (this.passwordConfirm !== this.passwordNew) {
        this.error = {
          confirPassword: "Las contraseñas no coinciden",
        };
        return;
      }
      const data_enviar = {
        password: this.password,
        new_password: this.passwordNew,
      };
      this.estudianteService.updateContrasenia(data_enviar).then((response) => {
        if (response.success === true) {
          this.$message.success(response.message);
        } else {
          this.error = {
            message: response.message,
          };
        }
        if (response.errors) {
          this.error = response.errors;
          return;
        }
      });
    },
  },
};
</script>
<style scoped>
.form-control {
  border: 1px solid #233269;
  font-size: 15px;
  font-weight: 700;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #14123b;
  color: #233269;
}
.btn {
  width: 100%;
  background-color: #14123b;
  color: #f7f1ee;
  border: 1px solid #14123b;
  border-radius: 5px;

  &:hover {
    background-color: #233269;
    color: #f7f1ee;
    border: 1px solid #233269;
  }
}
</style>
