<template>
  <div class="settings-widget card-details">
    <div class="settings-menu p-0">
      <div class="profile-heading">
        <h3>
          <i class="pi pi-users" style="font-size: 20px; font-weight: bold">
          </i>
          ADMINISTRAR CATEGORIAS :
          <button class="btn-new-user" @click="abrirModal()">
            <i
              class="pi pi-plus"
              style="font-size: 20px; font-weight: bold"
            ></i>
          </button>
        </h3>
      </div>
      <div class="checkout-form">
        <!-- Contenido de Pestañas -->
        <div class="tab-content">
          <div class="tab-pane show active">
            <div class="table-responsive custom-table">
              <table class="table table-nowrap mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NOMBRE</th>
                    <th>DESCRIPCIÓN</th>
                    <th>ESTADO</th>
                    <th>ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="categoria in paginationCategorias"
                    :key="categoria.id"
                  >
                    <td>{{ categoria.id }}</td>
                    <td>
                      {{ categoria.nombre }}
                    </td>
                    <td>{{ categoria.descripcion }}</td>
                    <td
                      :class="{
                        'text-success': categoria.estado == 1,
                        'text-danger': categoria.estado == 0,
                      }"
                    >
                      {{ categoria.estado == 1 ? "Activo" : "Inactivo" }}
                    </td>
                    <td>
                      <button @click="abrirModal(categoria)" class="btn-editar">
                        <i class="pi pi-user-edit"></i>
                      </button>
                      <button
                        :class="
                          categoria.estado === 1 ? 'btn-delete' : 'btn-activar'
                        "
                        @click="eliminarCategoria(categoria.id)"
                      >
                        <i
                          :class="
                            categoria.estado === 1
                              ? 'pi pi-times'
                              : 'pi pi-check'
                          "
                        ></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination :totalPages="totalPages" v-model:currentPage="currentPage" />
      </div>
    </div>
    <div
      v-if="mostrarModal"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAgregarCategoria"
      aria-hidden="true"
      style="display: block; background-color: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ esEdicion ? "Editar Categoría" : "Agregar Categoría" }}
            </h5>
            <button
              type="button"
              class="close"
              @click="cerrarModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="guardarCategoria">
              <div class="form-group">
                <label for="nombre">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  id="nombre"
                  v-model="categoriaForm.nombre"
                  required
                />
              </div>
              <div class="form-group">
                <label for="descripcion">Descripción</label>
                <input
                  type="text"
                  class="form-control"
                  id="descripcion"
                  v-model="categoriaForm.descripcion"
                />
              </div>
              <div class="form-group">
                <label for="estado">Estado</label>
                <select
                  class="form-control"
                  id="estado"
                  v-model="categoriaForm.estado"
                >
                  <option value="1">Activo</option>
                  <option value="0">Inactivo</option>
                </select>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="cerrarModal"
                >
                  Cerrar
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ esEdicion ? "Actualizar" : "Guardar" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoriaService from "@/service/CategoriaService";
import Pagination from "@/components/lms-pagination.vue";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      categorias: [],
      categoriaForm: {
        id: null,
        nombre: "",
        descripcion: "",
        estado: "1",
      },
      mostrarModal: false,
      esEdicion: false,
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.categorias.length / this.itemsPerPage);
    },
    paginationCategorias() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.categorias.slice(start, end);
    },
  },
  created() {
    this.categoria = new CategoriaService();
  },
  mounted() {
    this.obtenerCategorias();
  },
  methods: {
    abrirModal(categoria = null) {
      if (categoria) {
        // Modo edición
        this.esEdicion = true;
        this.categoriaForm = { ...categoria }; // Cargar datos de la categoría seleccionada
      } else {
        // Modo agregar
        this.esEdicion = false;
        this.categoriaForm = {
          id: null,
          nombre: "",
          descripcion: "",
          estado: "1",
        };
      }
      this.mostrarModal = true;
    },
    cerrarModal() {
      this.mostrarModal = false;
    },
    guardarCategoria() {
      if (this.esEdicion) {
        // Actualizar categoría
        let data = {
          id: this.categoriaForm.id,
          nombre: this.categoriaForm.nombre,
          descripcion: this.categoriaForm.descripcion,
          estado: this.categoriaForm.estado,
        };
        console.log(data);
        this.categoria.actualizarCategoria(data).then((response) => {
          console.log(response);
          if (response.success == false) {
            this.$message.error(response.message);
            return;
          } else {
            this.$message.success(response.message);
            this.obtenerCategorias();
            this.cerrarModal();
          }
        });
      } else {
        // Guardar nueva categoría
        let data = {
          nombre: this.categoriaForm.nombre,
          descripcion: this.categoriaForm.descripcion,
          estado: this.categoriaForm.estado,
        };
        this.categoria.guardarCategoria(data).then((response) => {
          if (response.success == false) {
            this.$message.error(response.message);
            return;
          } else {
            this.$message.success(response.message);
            this.obtenerCategorias();
            this.cerrarModal();
          }
        });
      }
    },
    obtenerCategorias() {
      this.categoria.obtenerCategorias().then((response) => {
        this.categorias = response.categorias || [];
      });
    },
    eliminarCategoria(id) {
      //LANZO UN ALERT PARA CONFIRMAR SI DESEA ELIMINAR
      if (!confirm("¿Está seguro de eliminar la categoría?")) {
        return;
      }
      this.categoria.eliminarCategoria(id).then((response) => {
        if (response.success == false) {
          this.$message.error(response.message);
          return;
        }
        this.$message.success(response.message);
        this.obtenerCategorias();
      });
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
<style></style>
