<template>
  <div class="row">
    <login-banner></login-banner>
    <div class="col-md-6 login-wrap-bg">
      <div class="login-wrapper">
        <div class="loginbox">
          <div class="w-100">
            <div class="logo-estilo">
              <img src="@/assets/img/logo/llave2.png" class="logo" alt="Logo" />
              
              <div class="back-home" v-show="false">
                <router-link to="/home/">Volver a Inicio</router-link>
              </div>
            </div>
            <h2>ACCEDE A TU CUENTA</h2>
            <Form
              @submit="iniciarSesion"
              :validation-schema="schema"
              v-slot="{ errors, isSubmitting }"
            >
              <div class="input-block">
                <span class="input-label">Correo electrónico</span>
                <div class="form-addons">
                  <Field
                    name="email"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                </div>
              </div>
              <div class="input-block password-group">
                <span class="input-label">Contraseña</span>
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <span
                    @click="toggleShow"
                    class="toggle-password"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                </div>
                <div class="d-flex align-items-center">
                  <button
                    type="submit"
                    class="btn btn-cuadrado"
                    :disabled="isSubmitting"
                  >
                    Iniciar sesión
                    <span
                      v-if="isSubmitting"
                      class="spinner-border spinner-border-sm"
                    ></span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
          <div class="auth-links">
            <p v-show="false">
              <router-link to="forgot-password" class="forgot-link">
                ¿Has olvidado tu contraseña?
              </router-link>
            </p>
            <p>
              <router-link to="register" class="crear-cuenta">
                Crear una cuenta
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuth } from "@/stores";

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      schema: Yup.object().shape({
        email: Yup.string()
          .required("El correo electrónico es obligatorio")
          .email("Formato de correo inválido"),
        password: Yup.string()
          .min(6, "La contraseña debe tener al menos 6 caracteres")
          .required("La contraseña es obligatoria"),
      }),
      isSubmitting: false,
      loginError: "",
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async iniciarSesion(values) {
      this.isSubmitting = true;
      this.loginError = "";

      try {
        await this.schema.validate(values, { abortEarly: false });

        const authStorage = useAuth();
        const loginSuccess = await authStorage.userLogin(values);

        if (loginSuccess) {
          this.$router.push("/home/");
        } else {
          this.loginError =
            "Credenciales incorrectas. Verifica tu correo y contraseña.";
        }
      } catch (validationErrors) {
        console.error("Errores de validación:", validationErrors);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped>
/* Estilos generales */
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Fondo de login */
.login-wrap-bg {
  background: #f7f1ee; /* Color de fondo actualizado */
  max-height: 100%; /* Asegura que el contenedor ocupe toda la altura disponible */
  overflow-y: auto; /* Agrega desplazamiento cuando sea necesario */
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginbox {
  background: #f7f1ee;
  padding: 30px;
  border-radius: 10px;
  flex: 1;
}

.logo-estilo {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 150px;
}
h2 {
  font-size: 24px;
  font-weight: 600;
  color: #14123b;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos del formulario */
.input-block {
  margin-bottom: 20px;
}

.password-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-label {
  font-size: 15px;
  color: #14123b; /* Color actualizado */
  display: block;
}

.form-control {
  width: 100%; /* Campo de texto expandido */
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #fafafa; /* Color de fondo de los campos */
}

.pass-group {
  position: relative;
}

.pass-input {
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
}

.forgot {
  text-align: right;
  margin-top: 10px;
}

.forgot-link {
  color: #14123b; /* Color actualizado */
  text-decoration: none;
}

.forgot-link:hover {
  text-decoration: underline;
}

/* Botón */
.btn-cuadrado {
  background-color: #14123b; /* Color actualizado */
  color: #fff;
  border: none;
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-cuadrado:disabled {
  background-color: #ccc;
}

.btn-cuadrado:hover {
  background-color: #1a2c4a; /* Sombra más oscura al pasar el ratón */
}

/* Estilos del enlace de Google y redirección */
.google-bg {
  margin-top: 20px;
}

.google-bg p {
  font-size: 14px;
  color: #333;
}

.google-bg a {
  color: #213367; /* Color actualizado */
}

.google-bg a:hover {
  text-decoration: underline;
}

/* Estilos de validación */
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Estilos de los enlaces (olvidaste contraseña, crear cuenta) */
.auth-links {
  text-align: center;
  margin-top: 20px;
}

.auth-links p {
  margin: 10px 0;
}

.crear-cuenta {
  color: #213367; /* Color actualizado */
  text-decoration: none;
  display: block; /* Centramos el texto */
  text-align: center; /* Centramos el texto de "Crear una cuenta" */
}

.crear-cuenta:hover {
  text-decoration: underline;
}
</style>
