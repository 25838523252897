<template>
  <div class="row">
    <register-banner></register-banner>

    <div class="col-lg-7 col-md-5 login-wrap-bg fondo_create">
      <!-- Login -->
      <div class="login-wrapper">
        <div class="loginbox register-box">
          <div class="img-logo">
            <div class="course-group profile-upload-group mb-0 d-flex">
              <div
                class="course-group-img profile-edit-field d-flex align-items-center"
              >
                <div to="/student/student-profile" class="profile-pic">
                  <img :src="src" alt="Img" class="img-fluid" />
                </div>
                <div class="profile-upload-head">
                  <h4>
                    <router-link to="/instructor/instructor-profile"
                      >Tu avatar</router-link
                    >
                  </h4>
                  <div class="new-employee-field">
                    <div class="d-flex align-items-center mt-2">
                      <FileUpload
                        mode="basic"
                        @select="onFileSelect"
                        customUpload
                        auto
                        choose-icon="pi pi-images"
                        chooseLabel=" "
                        severity="secondary"
                        class="p-button-outlined p-button-image"
                      />
                      <div class="img-delete">
                        <a href="#" class="delete-icon"
                          ><i class="bx bx-trash"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="back-home">
              <router-link to="/instructor/instructor-grid"
                >Regresar</router-link
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="personal-form transparente-fondo">
                <form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Nombres: <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingrese su nombre"
                          v-model="instructor.nombres"
                        />
                        <Message
                          class="text-danger"
                          v-if="errors.nombres"
                          severity="error"
                          variant="simple"
                          >{{ errors.nombres[0] }}</Message
                        >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Apellidos: <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingrese su apellido"
                          v-model="instructor.apellidos"
                        />
                        <Message
                          class="text-danger"
                          v-if="errors.apellidos"
                          severity="error"
                          variant="simple"
                          >{{ errors.apellidos[0] }}</Message
                        >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="sel1" class="form-label color_Text"
                          >Pais</label
                        >
                        <AutoComplete
                          class="form-control"
                          v-model="instructor.pais"
                          dropdown
                          :suggestions="paises"
                          placeholder="Seleccione un pais"
                          optionLabel="nombre"
                          optionValue="id"
                          style="padding: 0;"
                          @complete="searchPaises"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Telefono</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingrese su numero de telefono"
                          v-model="instructor.telefono"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Direccion</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingrese su direccion"
                          v-model="instructor.direccion"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Profesion</label
                        >
                        <select
                          name="profesion"
                          class="form-control"
                          v-model="instructor.profesion"
                          style="appearance: auto;"
                        >
                          <option value="Ingeniero de Sistemas">
                            Ingeniero de Sistemas
                          </option>
                          <option value="Ingeniero de Software">
                            Ingeniero de Software
                          </option>
                          <option value="Ingeniero de Electronica">
                            Ingeniero de Electronica
                          </option>
                          <option value="Ingeniero de Telecomunicaciones">
                            Ingeniero de Telecomunicaciones
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Email: <span class="text-danger">*</span></label
                        >
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Ingrese su correo"
                          v-model="instructor.email"
                        />
                        <Message
                          class="text-danger"
                          v-if="errors.email"
                          severity="error"
                          variant="simple"
                          >{{ errors.email[0] }}</Message
                        >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Password: <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingrese su contraseña"
                          v-model="instructor.password"
                        />
                        <Message
                          class="text-danger"
                          v-if="errors.password"
                          severity="error"
                          variant="simple"
                          >{{ errors.password[0] }}</Message
                        >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="form-control-label color_Text"
                          >Biografia:</label
                        >
                        <Textarea
                          v-model="instructor.biografia"
                          rows="5"
                          cols="30"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="d-grid mt-4">
                      <button
                        type="submit"
                        class="btn btn-info btn-start btn-crear"
                        @click="crearInstructor"
                      >
                        {{ id ? "Actualizar Instructor" : "Crear Instructor" }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Login -->
    </div>
  </div>
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";

import Message from "primevue/message";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Select from "primevue/select";
import FileUpload from "primevue/fileupload";
import Textarea from "primevue/textarea";

import InstructorService from "@/service/InstructorService";

export default {
  components: {
    AutoComplete,
    Button,
    Message,
    Select,
    FileUpload,
    Textarea,
  },
  data() {
    return {
      paises: [],
      instructor: {
        'profesion': 'Ingeniero de Sistemas'
      },
      errors: {},
      src: require("@/assets/img/user/avatar.png"),
      selectedFile: null,
    };
  },
  created() {
    this.instructorService = new InstructorService();
  },
  computed: {
    id() {
      return this.$route.params.id || null;
    },
  },
  mounted() {
    if (!this.id) return;
    this.obtenerUsuario();
  },
  methods: {
    submitForm() {
      this.$router.push("/register-step-two");
    },
    obtenerUsuario() {
      this.instructorService
        .instructordataModificar(this.id)
        .then((response) => {
          if (!response.success) {
            return;
          }
          this.instructor = response.instructor;
          this.src = response.instructor.foto
            ? response.instructor.foto
            : require("@/assets/img/user/avatar.png");
        });
    },
    searchPaises(event) {
      const query = {
        dato: event.query,
      };
      this.instructorService.buscarPais(query).then((response) => {
        this.paises = response.pais;
      });
    },
    crearInstructor(event) {
      event.preventDefault();
      if (!this.instructor.id) {
        const data_enviar = {
          ...this.instructor,
          pais_id: this.instructor.pais ? this.instructor.pais.id : null,
          image: this.selectedFile
        };
        this.instructorService.saveInstructor(data_enviar).then((response) => {
          if (response.errors) {
            this.errors = response.errors;
            return;
          } else if (response.success) {
            this.$message.success(response.message);
            this.$router.push("/instructor/instructor-grid");
          } else {
            this.$message.error(response.message);
          }
        });
      } else {
        const data_enviar = {
          ...this.instructor,
          pais_id: this.instructor.pais ? this.instructor.pais.id : null,
          image: this.selectedFile,
        };
        this.instructorService.updateInstructor(data_enviar).then((response) => {
            if (response.errors) {
              this.$message.error(response.message);
              this.errors = response.errors;
              return;
            } else if (response.success) {
              this.$message.success(response.message);
              this.$router.push("/instructor/instructor-grid");
            } else {
              this.$message.error(response.message);
            }
          });
      }
    },
    onFileSelect(event) {
      const file = event.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.src = e.target.result;
        this.selectedFile = reader.result.split(",")[1]; // Obtiene la cadena Base64 sin el encabezado
      };
      reader.readAsDataURL(file);
    },
    uploadImage() {
      if (!this.selectedFile) {
        return;
      }
      const payload = {
        image: this.selectedFile,
        name: this.nombre ?? "instructor",
      };
      this.instructorService.uploadImage(payload).then((response) => {
        if (!response.success) {
          this.$message.error(response.message);
        }
      });
    },
  },
};
</script>
<style>
.text-danger {
  color: red;
}
.login-wrapper {
  align-items: normal !important;
}
.login-wrapper .register-box {
  background: linear-gradient(-90deg, #14123b, transparent);
}
.color_Text {
  color: #14123b !important;
}
.transparente-fondo {
  background: transparent !important; /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Desenfoque */
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid #14123b !important;
}
.p-button-image:hover {
  color: #14123b !important;
  background: transparent !important;
}
.p-button-image {
  background: #14123b !important;
  border-color: #14123b !important;
  color: #f7f1ee !important;
}
.btn-crear {
  border-color: #14123b !important;
}
.btn-crear:hover {
  background: #130d86 !important;
}
.login-wrapper .loginbox label {
  margin-top: 10px;
  margin-bottom: 1px ! important;
}
</style>
