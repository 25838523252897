<template>
  <student-header></student-header>
  <hr />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- Historial de Pedidos del Estudiante -->
        <div class="col-xl-12 col-lg-12">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>
                  <i
                    class="pi pi-users"
                    style="font-size: 20px; font-weight: bold"
                  >
                  </i>
                  LISTA DE ESTUDIANTE :
                </h3>
              </div>
              <div class="checkout-form">
                <!-- Contenido de Pestañas -->
                <div class="tab-content">
                  <div class="tab-pane show active">
                    <div class="table-responsive custom-table">
                      <table class="table table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>NOMBRE</th>
                            <th>EMAIL</th>
                            <th>TELEFONO</th>
                            <th>PAIS</th>
                            <th>DIRECCION</th>
                            <th>REALIZADO POR</th>
                            <th>ESTADO</th>
                            <th>ACCIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="estudiante in paginatedEstudiantes"
                            :key="estudiante.id"
                          >
                            <td>{{ estudiante.id }}</td>
                            <td>
                              {{
                                estudiante.nombres + " " + estudiante.apellidos
                              }}
                            </td>
                            <td>{{ estudiante.email }}</td>
                            <td>{{ estudiante.telefono }}</td>
                            <td>{{ estudiante.pais }}</td>
                            <td>{{ estudiante.direccion }}</td>
                            <td>
                              {{ estudiante.realizado_por ?? "administrador" }}
                            </td>
                            <td>
                              <span
                                v-if="estudiante.estado === 1"
                                class="text-success"
                                >ACTIVO</span
                              >
                              <span
                                v-else
                                class="text-danger"
                                >INACTIVO</span
                              >
                            </td>
                            <td>
                              <button
                                :class="estudiante.estado === 1 ? 'btn-delete' : 'btn-activar'"
                                @click="activarDesactivar(estudiante.id)"
                              >
                              <i :class="estudiante.estado === 1 ? 'pi pi-times' : 'pi pi-check'"></i>
                            </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /Contenido de Pestañas -->
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :totalPages="totalPages"
          v-model:currentPage="currentPage"
        />
      </div>
    </div>
  </div>
  <layouts1></layouts1>
</template>
<script>
import Pagination from "@/components/lms-pagination.vue";
import EstudianteService from "@/service/EstudianteService";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      estudiantes: [],
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.estudiantes.length / this.itemsPerPage);
    },
    paginatedEstudiantes() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.estudiantes.slice(start, end);
    },
  },
  created() {
    this.estudianteService = new EstudianteService();
  },
  mounted() {
    this.obtenerEstudiantes();
  },
  methods: {
    obtenerEstudiantes() {
      this.estudianteService.getEstudiantes().then((response) => {
        if (!response.success) {
          return;
        }
        this.estudiantes = response.estudiantes;
      });
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    activarDesactivar(id) {
      this.estudianteService.activarDesactivar(id).then((response) => {
        if (!response.success) {
          this.$message.error(response.message);
        }
        this.obtenerEstudiantes();
      });
    },
  },
};
</script>
<style>
.title-course {
  display: block;
  font-weight: 900;
}
.text-success {
  color: #28a745;
  font-weight: 900;
}
.text-danger {
  color: #dc3545;
  font-weight: 900;
}

.custom-table table {
  font-size: 15px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #14123b;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.btn-new-user {
  color: #14123b;
  border: 1px solid #14123b;
  border-radius: 50px;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-new-user:hover {
  background-color: #14123b;
  color: #fff;
}

.btn-editar {
  font-size: 20px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  color: #14123b;
  border: 1px solid #14123b;
  border-radius: 5px;
}
.btn-delete {
  font-size: 20px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  color: #ed0000d5;
  border: 1px solid #ed0000d5;
  border-radius: 5px;
}
</style>
