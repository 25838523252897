<template>
  <student-header></student-header>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- barra lateral -->
        <student-sidebar></student-sidebar>
        <!-- /Barra lateral -->

        <!-- Configuración del Estudiante -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>
                  <i
                    class="pi pi-cog"
                    style="font-size: 20px; font-weight: bold"
                  >
                  </i>
                  Datos Personales
                </h3>
              </div>
              <form action="student-settings">
                <div class="course-group profile-upload-group mb-0 d-flex">
                  <div
                    class="course-group-img profile-edit-field d-flex align-items-center"
                  >
                    <div class="profile-pic">
                      <img :src="src" alt="Img" class="img-fluid" />
                    </div>
                    <div class="profile-upload-head">
                      <h4 v-if="false">
                        <router-link to="/student/student-profile"
                          >Tu avatar</router-link
                        >
                      </h4>
                      <!-- <p>PNG o JPG no mayor a 800px de ancho y alto</p> -->
                      <div class="new-employee-field">
                        <p>
                          <i
                            class="bx bx-info-circle"
                            style="color: #233269; font-size: 16px"
                          ></i>
                          PNG o JPG no mayor a 800px de ancho y alto
                        </p>
                        <div class="d-flex align-items-center">
                          <FileUpload
                            mode="basic"
                            @select="onFileSelect"
                            customUpload
                            auto
                            choose-icon="pi pi-images"
                            chooseLabel="Cambiar Avatar"
                            style="
                              background-color: #171723;
                              color: #f7f1ee;
                              border: 1px solid #f7f1ee;
                              border-radius: 5px;
                              padding: 5px 10px;
                              font-size: 14px;
                              font-weight: 500;
                              cursor: pointer;
                            "
                          />
                          <div class="img-delete" v-if="false">
                            <a href="#" class="delete-icon"
                              ><i class="bx bx-trash"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-form settings-wrap">
                  <div class="edit-profile-info" v-if="false">
                    <h5>Detalles Personales</h5>
                    <p>Edita tu información personal</p>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <strong>Nombres: </strong>
                      <InputText
                        v-model="estudiante.nombres"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <strong>Apellidos: </strong>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.apellidos"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <strong>Correo Electrónico: </strong>
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.email"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <strong>N&uacute;mero de Tel&eacute;fono: </strong>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.telefono"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <strong>Biografía: </strong>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.biografia"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <strong>Direcci&oacute;n: </strong>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.direccion"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <strong>Seleccione un País: </strong>
                      <div class="input-block">
                        <AutoComplete
                          v-model="pais"
                          dropdown
                          :suggestions="paises"
                          placeholder="Seleccione un pais"
                          optionLabel="nombre"
                          optionValue="id"
                          @complete="searchPaises"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <Button
                          icon="pi pi-refresh"
                          label="Actualizar Perfil"
                          class="btn btn-primary"
                          @click="actualizarEstudiante"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Configuración del Estudiante -->
      </div>
    </div>
  </div>
  <layouts1></layouts1>
</template>

<script>
import EstudianteService from "@/service/EstudianteService";
import UserService from "@/service/UserService";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";

export default {
  components: {
    InputText,
    AutoComplete,
    FileUpload,
    Button,
  },
  data() {
    return {
      title: "Configuraciones",
      text: "Inicio",
      text1: "Configuraciones",
      estudiante: {},
      user: {},
      src: require("@/assets/img/user/avatar.png"),
      paises: [],
      pais: {},
      selectedFile: null,
    };
  },
  created() {
    this.estudianteService = new EstudianteService();
    this.userService = new UserService();
  },
  mounted() {
    this.obtenerEstudiante();
  },
  methods: {
    obtenerEstudiante() {
      this.userService.getUser().then((response) => {
        if (!response.success) {
          return;
        }
        this.user = response.user;
        this.estudiante = response.usuario;
        this.pais = response.usuario.pais ?? null;
        this.src = response.usuario.foto
          ? response.usuario.foto
          : require("@/assets/img/user/avatar.png");
      });
    },
    searchPaises(event) {
      const query = {
        dato: event.query,
      };
      this.estudianteService.buscarPais(query).then((response) => {
        this.paises = response.pais;
      });
    },
    onFileSelect(event) {
      const file = event.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.src = e.target.result;
        this.selectedFile = reader.result.split(",")[1]; // Obtiene la cadena Base64 sin el encabezado
      };
      reader.readAsDataURL(file);
    },
    uploadImage() {
      if (!this.selectedFile) {
        return;
      }

      const payload = {
        image: this.selectedFile,
        name: this.estudiante.nombres ?? "estudiante",
      };
      this.estudianteService.uploadImage(payload).then((response) => {
        if (!response.success) {
          this.$message.success(response.message);
        }
      });
    },
    actualizarEstudiante() {
      const data_enviar = {
        ...this.estudiante,
        pais_id: this.pais.id ?? null,
        email: this.user.email ?? null,
        password: this.user.password ?? null,
      };
      this.uploadImage();
      this.estudianteService.updateEstudiante(data_enviar).then((response) => {
        if (response.success) {
          this.$message.success(response.message);
          this.obtenerEstudiante();
        } else {
          this.$message.error(response.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.form-control {
  border: 1px solid #233269;
  font-size: 15px;
  font-weight: 700;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #14123b;
  color: #233269;
}
.btn {
  width: 100%;
  background-color: #14123b;
  color: #f7f1ee;
  border: 1px solid #14123b;
  border-radius: 0;

  &:hover {
    background-color: #233269;
    color: #f7f1ee;
    border: 1px solid #233269;
  }
}
</style>
